// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Grid, Box, Link } from "@mui/material";
import Iconify from "src/components/Iconify";
import { Link as RouterLink } from "react-router-dom";
// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "15%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

// ----------------------------------------------------------------------

SummaryCard.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function SummaryCard({
  title,
  count,
  icon,
  link,
  color = "#000",
  sx,
  ...other
}) {
  return (
    // <Card
    //   sx={{
    //     py: 5,
    //     boxShadow: 0,
    //     textAlign: "center",
    //     color: "#655FB1",
    //     // bgcolor: "#99ceff",
    //     borderRadius:"50%",
    //     bgcolor: (theme) => theme.palette[color].lighter,
    //     width: 230,
    //     height: 230,
    //     ...sx,
    //   }}
    //   {...other}
    // >
    //   <Box sx={{ mx:"auto",justifyContent:"center"}}>
    //       <IconWrapperStyle
    //         sx={{
    //           width:80,
    //           height:80,
    //           color: (theme) => theme.palette[color].dark,
    //           backgroundImage: (theme) =>
    //             `linear-gradient(135deg, ${alpha(
    //               theme.palette[color].dark,
    //               0
    //             )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
    //         }}
    //       >
    //         <Iconify icon={icon} width={50} height={50} sx={{color:"#655FB1"}} />
    //       </IconWrapperStyle>
    //       <Typography variant="h4">{fShortenNumber(count)}</Typography>
    //       <Typography variant="h6" sx={{ opacity: 0.72 }}>
    //         {title}
    //       </Typography>
    //   </Box>
    // </Card>
    <Card
      sx={{
        boxShadow: 5,
        textAlign: "center",
        // color: (theme) => theme.palette[color].darker,
        bgcolor: color,
        // bgcolor: (theme) => theme.palette[color].lighter,
        // width: 250,
        height: 100,
        borderRadius: 1,
        ...sx,
      }}
      {...other}
    >
      <Grid container rowSpacing={1}>
        <Grid item xs={3}>
          <IconWrapperStyle
            sx={{
              my: 3.5,
              width: 45,
              height: 45,
              ml: 1.5,
              // color: (theme) => theme.palette[color].dark,
              bgcolor: "#fff",
              // backgroundImage: (theme) =>
              //   `linear-gradient(135deg, ${alpha(
              //     theme.palette[color].dark,
              //     0
              //   )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
            }}
          >
            <Iconify
              icon={icon}
              width={25}
              height={25}
              sx={{ color: { color } }}
            />
          </IconWrapperStyle>
        </Grid>
        <Grid item xs={9} sx={{ my: 2, color: "#fff" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {/* {fShortenNumber(count)} */}
            {Math.round(count * 10) / 10}
          </Typography>
          <Typography variant="subtitle1" sx={{ opacity: 1, width: "98%" }}>
            {title}
          </Typography>
          {link && (
            <Link
              sx={{
                color: "#6868cd",
                // fontWeight: 700,
                textDecoration: "underline",
              }}
              component={RouterLink}
              variant="subtitle2"
              to={`/dashboard-coins-info`}
            >
              History!
            </Link>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
