import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";

function ConfirmationWithDescription({
  open,
  setOpen,
  handleAgree,
  title,
  descriotion,
  isJustForOk,
}) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {descriotion}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isJustForOk ? (
            <Button className="model-button-hover" onClick={handleCloseDialog}>
              Ok
            </Button>
          ) : (
            <>
              <Button
                className="model-button-hover"
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
              <Button className="model-button-hover" onClick={handleAgree}>
                Agree
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
    </>
  );
}

export default ConfirmationWithDescription;
