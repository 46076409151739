import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import UserInfoCard from "./UserInfoCard";
import { s3baseUrl } from "src/config/config";
import { Box, Button, FormHelperText, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { send_coins } from "src/DAL/Community/Community";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { memberDetail } from "src/DAL/Profile/Profile";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  minHeight: 330,
  maxHeight: 330,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  pt: 0,
  borderRadius: 1,
  overflow: "auto",
};
export default function SendCoins({
  sendCoins,
  handleCloseSimpleBox,
  feeds_list,
  handleUpdateSpecificFeed,
}) {
  const [comment, setComment] = useState("");
  const [coinCount, setCoinCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, setUserInfo } = useContentSetting();
  const [totalPoints, setTotalPoints] = useState(0);
  const [availablecoins, setAvailablecoins] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      shared_points: coinCount,
      description: comment,
      receiver: feeds_list.created_by._id,
      feed: feeds_list._id,
    };
    if (coinCount <= 0) {
      enqueueSnackbar("Please Add Coins to Send", { variant: "error" });
    } else {
      setIsLoading(true);
      const result = await send_coins(postData);
      if (result.code === 200) {
        let info = userInfo;

        // Update the coins value
        info.total_points = totalPoints;
        setUserInfo(info);
        enqueueSnackbar(result.message, { variant: "success" });
        handleUpdateSpecificFeed(feeds_list._id);
        handleCloseSimpleBox();
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const getMemberData = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      setAvailablecoins(result?.member?.total_points);
      setTotalPoints(result?.member?.total_points);
    }
  };

  useEffect(() => {
    let coins = availablecoins - coinCount;
    if (coins >= 0) {
      setTotalPoints(coins);
    } else {
      setTotalPoints(availablecoins);
    }
  }, [coinCount]);
  useEffect(() => {
    getMemberData();
  }, []);

  return (
    <div>
      <Modal
        open={sendCoins}
        onClose={handleCloseSimpleBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="create-post-title-form modal-theme">
            {/* Close button */}
            <button
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
              }}
              className="cross-show-send-coins"
              onClick={handleCloseSimpleBox}
            >
              &times;
            </button>

            <h5 className="sendcoins-heading">
              Send coins to {feeds_list.created_by?.name}
            </h5>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  {" "}
                  <TextField
                    label="Add a comment"
                    variant="outlined"
                    multiline
                    rows={2}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </div>
                <p className="send-points">
                  Available points are {totalPoints}
                </p>
                <div className="col-12">
                  <TextField
                    label="Coin count"
                    sx={{ marginTop: 1 }}
                    variant="outlined"
                    required
                    type="number"
                    value={coinCount}
                    // onChange={(e) => setCoinCount(e.target.value)}
                    onChange={(e) => {
                      const input = parseInt(e.target.value);
                      if (!isNaN(input) && input <= availablecoins) {
                        setCoinCount(input);
                      } else if (e.target.value === "") {
                        setCoinCount("");
                      }
                    }}
                    fullWidth
                    onWheel={(event) => event.target.blur()}
                    margin="normal"
                  />
                  <FormHelperText sx={{ fontSize: 10 }}>
                    (You cannot send coins more than your available coins.)
                  </FormHelperText>
                </div>
                <div className="col-12 text-end">
                  <button
                    className="small-contained-button"
                    type="submit"
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
