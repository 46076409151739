import * as React from "react";

import Typography from "@mui/material/Typography";

import { Avatar, Box, Button, Chip } from "@mui/material";
import {
  bgFullImage,
  itemFeatureLayer_1,
  itemFeatureLayer_2,
  itemFeatureLayer_3,
  itemFeatureLayer_4,
  profileBg,
} from "src/assets";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import { ReactVideoPlayer } from "src/components";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

let DataArray = [
  {
    image: bgFullImage,
    title: "Daily dose of imotions",
    description: "",
    program: 8,
    lesson: 4,
  },
  {
    image: profileBg,
    title: "Lesson on Letting Go off Anger",
    description: "",
    program: 1,
    lesson: 3,
  },
  {
    image: itemFeatureLayer_2,
    title: "Best Motivational Words- Muniba ",
    description: "",
    program: 7,
    lesson: 1,
  },
  {
    image: itemFeatureLayer_1,
    title: "1 Powerful Shakti Success",
    description: "",
    program: 5,
    lesson: 4,
  },

  {
    image: itemFeatureLayer_3,
    title: "Hard work motivation 🔥 Best Study ",
    description: "",
    program: 1,
    lesson: 1,
  },
  {
    image: itemFeatureLayer_4,
    title: " plays A Very Important Role in Life",
    description: "",
    program: 2,
    lesson: 3,
  },

  ,
];
export default function VaultActivity({ replayData }) {
  function TextTruncate(text) {
    const words = text.split(" ");
    const isTruncated = words.length > 9;
    const truncatedText = isTruncated
      ? words.slice(0, 9).join(" ") + "..."
      : text;
    return truncatedText;
  }
  return (
    <div>
      <div class="card ">
        <div class="" style={{ height: "350px" }}>
          <Scrollbar
            sx={{
              height: "100%",
              "& .simplebar-content": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <div class="card-body no-scroll">
              {replayData.map((item, index) => {
                return (
                  <div
                    class="card mb-2 "
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                    }}
                  >
                    <div class="row no-gutters">
                      <div class="col-lg-5">
                        <div className="h-100 w-100 thumbnail">
                          <ReactVideoPlayer url={item?.video_url} />
                        </div>
                      </div>

                      <div class="col-lg-7 d-flex ">
                        <div class=" mt-1">
                          <Typography
                            className="mb-1 title"
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: "#ffff",
                            }}
                          >
                            {item?.title}
                          </Typography>
                          <Typography
                            className="mb-1 mt-1"
                            sx={{
                              fontSize: 12,
                              color: "#ffff",
                              opacity: 0.7,
                            }}
                          >
                            {TextTruncate(item?.short_description)}
                          </Typography>
                          <span
                            style={{
                              display: "flex",
                              marginTop: "15px",
                              marginBottom: "5px",
                            }}
                          >
                            {item.vault_category && (
                              <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                sx={{
                                  fontSize: 10,
                                  padding: "3px",
                                  marginRight: "3px",
                                }}
                              >
                                {item.vault_category?.vault_title}
                              </Button>
                            )}

                            <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              sx={{
                                fontSize: 10,
                                padding: "3px",
                              }}
                            >
                              {item.program_info?.title}
                            </Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Scrollbar>
        </div>
      </div>
    </div>
  );
}
