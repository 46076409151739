import { Icon } from "@iconify/react";
import androidFilled from "@iconify/icons-ant-design/android-filled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { get_root_value } from "src/utils/domUtils";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: get_root_value("--portal-theme-primary"),
  backgroundColor: "#ffcd6521",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: get_root_value("--portal-theme-primary"),
  backgroundImage: `linear-gradient(135deg, ${alpha(
    get_root_value("--portal-theme-primary"),
    0
  )} 0%, ${alpha(get_root_value("--portal-theme-primary"), 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppTotalSales({ total }) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={"fluent:food-24-filled"} width={24} height={24} />
        {/* <b style={{ fontSize: "30px" }}>V.</b> */}
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(total)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Food Matters
      </Typography>
    </RootStyle>
  );
}
