import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  hv1Image,
  hv2Image,
  hv3Image,
  hv4Image,
  hv5Image,
  hv6Image,
} from "src/assets";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";

import { programmesListing } from "../../DAL/Programmes/Programmes";
import { videos_listing } from "src/DAL/HelpingVideos/AdminHelpingVideos";
import { helping_video_Category_listing_api } from "src/DAL/HelpingVideos/AdminHelpingVideosCategory";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

export default function HelpingVideosCategory() {
  const navigate = useNavigate();
  const [tutorialsData, setTutorialsData] = useState([]);
  const [tutorialsVideos, setTutorialsVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState("");
  const [videosCategory, setVideosCategory] = useState([]);
  const programsList = async () => {
    let postData = {
      search: "",
      type: 1,
    };
    const result = await helping_video_Category_listing_api(postData);
    if (result.code == 200) {
      setVideosCategory(result.helping_video_categories);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    programsList();
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <h2>Helping videos Category</h2>
        </div>

        {videosCategory.length > 0 ? (
          videosCategory.map((value, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div
                  className="card mt-4 pods-cards-shadow cursor h-100"
                  onClick={() =>
                    navigate(
                      `/helping_videos_category/helping-videos/${value._id}`,
                      {
                        state: value,
                      }
                    )
                  }
                >
                  <img
                    src={s3baseUrl + value?.image?.thumbnail_1}
                    className="card-img-top pods-image"
                    alt="Programme"
                  />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="h2-heading">{value.title}</h3>
                      </div>

                      <div className="col-2 menu-option"></div>
                    </div>
                    <p className="programme-card-desc">{value.description}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <RecordNotFound title={"Categories Not Found"} />
        )}
      </div>
    </div>
  );
}
