import React, { useEffect, useCallback } from "react";
import ReactPlayer from "react-player";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const ReactVideoPlayer = (props) => {
  const playerRef = React.useRef();
  const [playedSecond, setPlayedSeconds] = React.useState("");
  const { userInfo } = useContentSetting();
  const [isReady, setIsReady] = React.useState(false);

  const setVideoPlayed = () => {
    if (props.setting && props.setting.video_type !== "repeat") {
      localStorage.setItem("is_seen_video", "Yes");
    }
  };
  const setVideoPlayedSec = (e) => {
    // props.getPlayedSec(e.playedSeconds);
    let dataKey = props.id;
    let _url = props.url;
    let user_id = userInfo?._id;
    let user_Info = localStorage.getItem(`${user_id}`);
    let data = {};
    if (user_Info) {
      data = JSON.parse(user_Info);
    }
    data[dataKey] = {
      url: _url,
      playedSec: e.playedSeconds,
    };

    // console.log("sdfgjskdlk", JSON.parse(user_Info));
    localStorage.setItem(`${user_id}`, JSON.stringify(data));
  };
  const onReady = useCallback(() => {
    if (!isReady) {
      let timeToStart = 0;
      timeToStart = parseFloat(props?.video_duration - 0.5);
      if (timeToStart <= 0) {
        playerRef.current.seekTo(0);
      } else {
        playerRef.current.seekTo(timeToStart, "seconds");
      }

      setIsReady(true);
    }
  }, [isReady]);

  return (
    <div className="wrapper">
      <ReactPlayer
        ref={playerRef}
        controls
        className="player"
        url={props.url}
        width="100%"
        height="100%"
        stopOnUnmount
        pip
        playsinline
        onPlay={setVideoPlayed}
        // onReady={onReady}
        onProgress={setVideoPlayedSec}
      />
    </div>
  );
};

export default ReactVideoPlayer;
