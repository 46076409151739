import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { CircularProgress } from "@mui/material";
import { VaultFilterSidebar } from "../../components/_dashboard/vault";
import { ReactVideoDurationPlayer } from "src/components";
import Chip from "@mui/material/Chip";
import { s3baseUrl } from "src/config/config";
import {
  filteredRecordingList,
  submitVaultFilter,
  vaultCategorySidebarListing,
} from "src/DAL/Programmes/Programmes";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
import { Icon } from "@iconify/react";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Vault(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { userInfo, adminTimeZone } = useContentSetting();
  const [openFilter, setOpenFilter] = useState(false);
  const [recordingsList, setRecordingsList] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterdata] = useState({});
  const [programOptions, setProgramOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const { contentSettingData, handleVideoList } = useContentSetting();
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [loadMore, setLoadMore] = useState(
    "api/program_recording/get_filtered_recording_list?page=0&limit=10"
  );
  const [videoProgressData, setVideoProgressData] = useState([]);

  const getVaultCategorySidebarListing = async () => {
    setIsLoading(true);
    const result = await vaultCategorySidebarListing();
    if (result.code === 200) {
      setProgramOptions(result.program);
      setCategoryOptions(result.vault_category);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      gender: "",
      category: "",
      colors: "",
      priceRange: "",
      rating: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;
  const handleSetLocal = (value, e) => {
    console.log(value, "dfklajsd", e);
    let dataKey = recordingData?._id;
    let _url = recordingData?.video_url;
    let user_id = userInfo?._id;
    let user_Info = localStorage.getItem(`${user_id}`);
    let data = {};
    if (user_Info) {
      data = JSON.parse(user_Info);
    }
    data[dataKey] = {
      url: _url,
      playedSec: value,
    };

    console.log("sdfgjskdlk", JSON.parse(user_Info));
    localStorage.setItem(`${user_id}`, JSON.stringify(data));
  };
  const handleOpenFilter = () => {
    setOpenFilter(true);
    setFilterdata({});
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const getFilteredRecordingList = async () => {
    const result = await filteredRecordingList(
      loadMore,
      JSON.stringify(filterData)
    );
    if (result.code === 200) {
      let data = result.recording.map((records) => {
        return {
          recording_id: records._id,
          video_duration: records?.video_duration,
          total_video_duration: records?.total_video_duration,
          is_complete: records?.is_complete,
        };
      });

      console.log(result, "vault result");
      setVideoProgressData(data);
      handleVideoList(data);
      localStorage.setItem("video_list", JSON.stringify(data));
      localStorage.setItem("video_type", "replay_library");
      var newArray = recordingsList.concat(result.recording);
      setRecordingsList(newArray);
      setIsLoading(false);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const onSubmitFilter = async (dataObject) => {
    setIsLoading(true);
    const result = await submitVaultFilter(dataObject);
    if (result.code === 200) {
      let data = result.recording.map((records) => {
        return {
          recording_id: records?._id,
          video_duration: records?.video_duration,
          total_video_duration: records?.total_video_duration,
          is_complete: records?.is_complete,
        };
      });
      setVideoProgressData(data);
      handleVideoList(data);
      localStorage.setItem("video_list", JSON.stringify(data));
      localStorage.setItem("video_type", "replay_library");
      setRecordingsList(result.recording);
      setIsLoading(false);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const withNoFilter = async () => {
    setIsLoading(true);
    const result = await submitVaultFilter(filterData);
    if (result.code === 200) {
      let data = result.recording.map((records) => {
        return {
          recording_id: records?._id,
          video_duration: records?.video_duration,
          total_video_duration: records?.total_video_duration,
          is_complete: records?.is_complete,
        };
      });
      setVideoProgressData(data);
      handleVideoList(data);
      localStorage.setItem("video_list", JSON.stringify(data));
      localStorage.setItem("video_type", "replay_library");
      setRecordingsList(result.recording);
      setIsLoading(false);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages + 1);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    setLoadMore(loadMorePath);
  };

  const handleResetFilter = () => {
    getVaultCategorySidebarListing();
    handleCloseFilter();
    onSubmitFilter({});
  };
  const handleNavigate = (value) => {
    window.open(value, "_blank");
  };

  useEffect(() => {
    getVaultCategorySidebarListing();
  }, []);

  useEffect(() => {
    withNoFilter();
  }, []);

  useEffect(() => {
    getFilteredRecordingList();
  }, [loadMore]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
          {contentSettingData && contentSettingData.vault_description ? (
            <div
              className="dashboard_description"
              dangerouslySetInnerHTML={{
                __html: contentSettingData.vault_description,
              }}
            ></div>
          ) : (
            <h2>Replay Library</h2>
          )}
        </div>
        <div className="col-12 svg-color">
          <VaultFilterSidebar
            formik={formik}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            setFilterdata={setFilterdata}
            setIsLoading={setIsLoading}
            programOptions={programOptions}
            categoryOptions={categoryOptions}
            setProgramOptions={setProgramOptions}
            setCategoryOptions={setCategoryOptions}
            onSubmitFilter={onSubmitFilter}
          />
        </div>
      </div>
      {recordingsList.length > 0 ? (
        recordingsList.map((value, index) => {
          // console.log(value, "dafjflka");
          return (
            <div className="card recordings-card h-100 secondary-background">
              <div className="row section-space">
                <div className="col-10">
                  <h3 className="programmes-heading">
                    {htmlDecode(value?.title)}
                  </h3>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 text-end">
                  <p className="vault-date text-secondary">
                    {/* {value?.recording_date} */}
                    {moment(value?.recording_date).format("DD MMM YYYY")}
                  </p>
                </div>
              </div>
              <div className="row media-margin">
                <div className="col-12">
                  <ReactVideoDurationPlayer
                    url={value?.video_url}
                    // getPlayedSec={handleSetLocal}
                    // id={value?._id}
                    value={value}
                    setVideoProgressData={setVideoProgressData}
                    videoProgressData={videoProgressData}
                    type={"replay_library"}
                  />
                </div>

                {value.audio_recording && (
                  <div className="col-12 mt-3 text-center">
                    <div>
                      <audio
                        controlsList="nodownload"
                        className="w-100"
                        src={s3baseUrl + value?.audio_recording}
                        controls
                      />
                    </div>
                  </div>
                )}

                <div className="col-12 section-space text-end">
                  {value.is_complete ? (
                    <Icon
                      icon="mdi:tick-circle"
                      width={30}
                      height={30}
                      color="primary"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-12 section-space">
                  <p>{htmlDecode(value?.short_description)}</p>
                </div>
                <div className="col-sm-12 col-md-7 program-btn">
                  <button className="small-contained-button ">
                    {htmlDecode(value?.program?.title)}
                  </button>
                </div>
                <div className="col-sm-12 col-md-5 program-category-btn">
                  {value?.vault_category?.vault_title && (
                    <Chip
                      label={htmlDecode(value?.vault_category?.vault_title)}
                      color="primary"
                      className="me-1"
                      variant="outlined"
                    />
                  )}
                  {value?.button_text && value?.button_link && (
                    <Chip
                      label={htmlDecode(value?.button_text)}
                      color="primary"
                      className="me-1"
                      variant="outlined"
                      onClick={() => handleNavigate(value?.button_link)}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <RecordNotFound title="Recordings" />
      )}
      {totlePages > pageNumber ? (
        <div className="col-12 text-center">
          <button
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Vault;
