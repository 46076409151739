// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../components/Page";
// import {
//   AppTasks,
//   AppNewUsers,
//   AppBugReports,
//   AppItemOrders,
//   AppNewsUpdate,
//   AppMindSet,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppWebsiteVisits,
//   AppTrafficBySite,
//   AppCurrentSubject,
//   AppConversionRates,
// } from "../components/_dashboard/app";
import DashboardCircle from "src/components/_dashboard/app/DashboardCircle";
import { ReactVideoPlayer } from "src/components";
import EventCarousel from "src/components/_dashboard/app/EventCarousel";
import ReviewCardSlider from "src/components/_dashboard/app/ReviewCards";
import ShowEvenetSlider from "src/components/_dashboard/app/EventSlider";
import { useContext, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { project_name } from "src/config/config";
import DashboardCard from "src/components/_dashboard/app/DashboardCard";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import Disclaimer from "src/components/Disclaimer";
import { useEffect } from "react";
import { ProjectInfoApi, memberDetail } from "src/DAL/Profile/Profile";
import { bgConsImage } from "src/assets";
import AppTotalSales from "src/components/_dashboard/app/AppTotalSale";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import AppMindSet from "src/components/_dashboard/app/AppMindSet";
import AppWorkoutCoin from "src/components/_dashboard/app/AppBugReports";
import AppCommunity from "src/components/_dashboard/app/AppCommunity";
import AppTotalCoins from "src/components/_dashboard/app/AppTotalCoins";
import AppCoins from "src/components/_dashboard/app/AppCoins";
import SummaryCard from "src/components/_dashboard/app/SummaryCard";
// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function getRandomRGBColor() {
  return "linear-gradient(135deg, rgb(255 216 101) 0%, rgb(33 32 32 / 8%) 100%)";
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  // Construct an RGB color string
  const color1 = `rgb(${red}, ${green}, ${blue}, 0)`;
  const color2 = `rgb(${red}, ${green}, ${blue}, 0.24)`;
  return `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`;
}
function getRandomRGBColor2() {
  return "#faa90008";
  // Generate random values for red, green, and blue channels
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  // Construct an RGB color string
  const color1 = `rgb(${red}, ${green}, ${blue}, 0.21)`;
  const color2 = `rgb(${red}, ${green}, ${blue}, 0)`;
  return `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`;
}
export default function DashboardApp() {
  const {
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    dashboardEventData,
  } = useContentSetting();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [disclaimerDetail, setDisclaimerDetail] = useState("");
  const [disclaimerHeading, setDisclaimerHeading] = useState("");
  const [disclaimerLabel, setDisclaimerLabel] = useState("");
  const [disclaimerButton, setDisclaimerButton] = useState("");
  const [disclaimerStatus, setDisclaimerStatus] = useState("");
  const [memberData, setMemberData] = useState({});
  const [memberPoints, setMemberPoints] = useState();

  const is_seen_video = _get_is_seen_video_localStorage();
  // console.log(
  //   dashboardEventData.filter((data) => data.created_for == "general"),
  //   "dashboardEventData"
  // );
  // console.log(dashboardSettingData, "ok");
  //Getting Project Info from API
  console.log(memberPoints, "====memberPoints===");
  const [dummyData, setDummyData] = useState([]);
  const getProjectInfo = async () => {
    const result = await ProjectInfoApi();
    if (result.code === 200) {
      setDisclaimerDetail(result.member_setting.disclaimer_text);
      setDisclaimerStatus(result.member_setting.is_disclaimer_show);
      setDisclaimerButton(result.member_setting.disclaimer_button_text);
      setDisclaimerLabel(result.member_setting.checkbox_label);
      setDisclaimerHeading(result.member_setting.disclaimer_heading);
      if (result.member_setting.is_disclaimer_show) {
        setopenPasswordModal(true);
      }
      getMemberData();
    }
  };
  const getMemberData = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      setMemberPoints(result?.reward_points);
      setMemberData(result.member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getProjectInfo();
  }, []);
  useEffect(() => {
    setDummyData([
      {
        color: "#3da5f4",
        title: "Workout",
        count: memberPoints?.workout ? memberPoints?.workout : 0,
        icon: "mdi:gym",
        link: false,
      },
      {
        color: "#fda006",
        title: "Food Matters",
        count: memberPoints?.food ? memberPoints?.food : 0,
        icon: "fluent:food-24-filled",
        link: false,
      },
      {
        color: "#9f3bf7",

        title: "Mindset",
        count: memberPoints?.mindset ? memberPoints?.mindset : 0,
        icon: "flowbite:brain-outline",
        link: false,
      },
      // {
      //   color: "#dd5b2c",

      //   title: "Video",
      //   count: memberPoints?.video ? memberPoints?.video : 0,
      //   icon: "lets-icons:video-fill",
      //   link: false,
      // },
      // {
      //   color: "#c42595",

      //   title: "Program Video",
      //   count: memberPoints?.program_video ? memberPoints?.program_video : 0,
      //   icon: "lets-icons:video-fill",
      //   link: false,
      // },
      {
        color: "#00cccc",
        title: "Community",
        count: memberPoints?.community ? memberPoints?.community : 0,
        icon: "healthicons:group-discussion-meetingx3",
        link: false,
      },
      {
        color: "#bccc00",
        title: "Bonus",
        count: memberPoints?.bonus ? memberPoints?.bonus : 0,
        icon: "fa6-solid:coins",
        link: false,
      },
      {
        color: "#0acf97",
        title: "Available Coins",
        count: memberData?.total_points ? memberData?.total_points : 0,
        icon: "ph:hand-coins-fill",
        link: true,
      },
    ]);
  }, [memberPoints, memberData]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     height: "100%",
    //   }}
    // >
    //   <img
    //     src={bgConsImage}
    //     alt="under construction"
    //     style={{ height: "468px" }}
    //   />
    // </div>
    <Page title="Dashboard ">
      <div className="container">
        <div className="row">
          <div className="col-12 dashboard_description">
            {contentSettingData && contentSettingData.dashboard_description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.dashboard_description,
                }}></div>
            ) : (
              <h2 className="dashboard-heading">
                {dashboardSettingData?.wellcome_text}
              </h2>
            )}
          </div>
        </div>

        <Grid container spacing={3}>
          {dummyData.map((item, i) => (
            <Grid item xs={12} sm={6} md={3}>
              {/* <AppCoins
                color={getRandomRGBColor}
                title={item?.title}
                count={item?.count}
                icon={item?.icon}
                color2={getRandomRGBColor2}
              /> */}
              <SummaryCard
                color={item.color}
                title={item.title}
                count={item.count}
                icon={item.icon}
                link={item.link}
              />
            </Grid>
          ))}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppTotalCoins
              color={getRandomRGBColor}
              title="Total Earned Coins"
              count={
                memberPoints?.total_points ? memberPoints?.total_points : 0
              }
              icon={"game-icons:two-coins"}
              color2={getRandomRGBColor2}
            />
          </Grid> */}
        </Grid>

        {/* <div className="row">
          <div className="col-12 mt-5 mx-auto text-center">
          <DashboardCard data={dashboardSettingData} />
        </div>
          {(project_name == "DD" ||
            project_name == "BASIC_DEV" ||
            project_name == "PGI_DEV") && (
            <>
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <DashboardCircle data={dashboardSettingData} />
              </div>
            </>
          )}
          {dashboardSettingData?.video_url ? (
            <span>
              {is_seen_video === "No" ? (
                <div className="col-sm-12 col-md-12 col-lg-12 my-5">
                  <ReactVideoPlayer
                    url={dashboardSettingData?.video_url}
                    setting={dashboardSettingData}
                  />
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: dashboardSettingData?.welcome_image,
                  }}
                ></div>
              )}
            </span>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: dashboardSettingData?.welcome_image,
              }}
            ></div>
          )}
          <div className="col-sm-12 col-md-12 col-lg-12 mt-5 text-center">
            <EventCarousel />
            <ShowEvenetSlider
            headingData={dashboardSettingData.upcoming_events_heading}
            data={dashboardEventData}
          />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-5 text-center">
            <ReviewCardSlider
            headingData={dashboardSettingData.client_reviews_heading}
            data={dashboardTestimonialData}
          />
          </div>
        </div>
        {memberData.disclaimer_status == false && (
          <Disclaimer
            openPasswordModal={openPasswordModal}
            setopenPasswordModal={setopenPasswordModal}
            disclaimerDetail={disclaimerDetail}
            disclaimerStatus={disclaimerStatus}
            disclaimerButton={disclaimerButton}
            disclaimerHeading={disclaimerHeading}
            disclaimerLabel={disclaimerLabel}
          />
        )} */}
      </div>
    </Page>
  );
}
