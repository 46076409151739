// Modal.js
import React from "react";
import ReactDOM from "react-dom";
import { s3baseUrl } from "src/config/config";
import { animatedImage } from "src/assets";
const CongratulationAnimation = ({ rewardData }) => {
  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <img src={animatedImage} />
    </div>,
    document.getElementById("modal-root")
  );
};

export default CongratulationAnimation;
