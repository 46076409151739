import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  add_feed_comment,
  edit_feed_comment,
} from "src/DAL/Community/Comments";

import { emoji_picker_icon, upload_image } from "src/assets";
import { useRef } from "react";
import EmojiPicker from "src/components/EmojiPicker";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { s3baseUrl } from "src/config/config";
export default function AddComment({
  post_id,
  feedsData,
  formAction,
  setFormAction,
  setShowAddComment,
  handleUpdateSpecificFeed,
  setCommentSelected,
  commentSelected,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState("");
  const [commentId, setCommentId] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const settings = useContentSetting();
  const inputRef = useRef(null);
  const { socket } = settings;
  const [image, setImage] = React.useState("");
  const [previews, setPreviews] = useState("");
  const [oldImage, setOldImage] = useState("");

  //Adding Category
  const handleSubmit = async (e) => {
    e.preventDefault();

    const postData = {
      feed: post_id,
      // parent_comment: "65ccb3b64fcaece4a8f289f7",
      text: state,
    };

    const formData = new FormData();
    formData.append("feed", post_id);
    formData.append("text", state);
    if (image) {
      formData.append("comment_image", image);
    }
    if (state == "" && !image) {
      enqueueSnackbar("At least  add Text or an Image in Comment", {
        variant: "error",
      });
    } else {
      setIsLoading(true);
      const result = await add_feed_comment(formData);
      if (result.code === 200) {
        // const socketData = {
        //   action: "add_comment",
        //   feed_id: post_id,
        //   token: localStorage.getItem("token"),
        //   creator_id: result.action_response.creator_id,
        //   action_by: result.action_response.sender,
        //   action_response: result.action_response,
        // };

        // socket.emit("feed_room_action_event", socketData);
        handleRemove();
        handleUpdateSpecificFeed(post_id);
        setState("");
        setShowEmojiPicker(false);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("text", state);
    if (oldImage || image) {
      if (image) {
        formData.append("comment_image", image);
        formData.append("is_cancelled", false);
      } else if (commentSelected.comment_image?.thumbnail_1) {
        formData.append("is_cancelled", false);
      }
    } else {
      formData.append("is_cancelled", true);
    }
    if (state == "" && !image && !oldImage) {
      enqueueSnackbar("At least  add Text or an Image in Comment", {
        variant: "error",
      });
    } else {
      setIsLoading(true);
      const result = await edit_feed_comment(formData, commentId);
      if (result.code === 200) {
        // const socketData = {
        //   action: "edit_comment",
        //   feed_id: post_id,
        //   comment: commentId,
        //   token: localStorage.getItem("token"),
        //   creator_id: result.action_response.creator_id,
        //   action_by: result.action_response.sender,
        //   action_response: result.action_response,
        // };

        // socket.emit("feed_room_action_event", socketData);
        handleUpdateSpecificFeed(post_id);
        handleRemove();
        setState("");
        setShowEmojiPicker(false);
        setIsLoading(false);
        setFormAction("ADD");
        setShowAddComment(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setState(value);
  };
  const handleRemove = () => {
    setPreviews("");
    setOldImage("");
    setImage("");
  };
  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const handleCancelUpdate = (e) => {
    e.preventDefault();
    setState("");
    setFormAction("ADD");
    setShowAddComment(false);
  };

  useEffect(() => {
    if (formAction === "EDIT") {
      // setState(feedsData.comment[0].message);
      setState(commentSelected.text);
      setCommentId(commentSelected._id);
      if (commentSelected.comment_image?.thumbnail_1) {
        setOldImage(commentSelected.comment_image?.thumbnail_1);
      }
    }
  }, [formAction]);

  return (
    <>
      <div className="new-memories">
        <form onSubmit={formAction === "ADD" ? handleSubmit : handleUpdate}>
          <TextField
            inputRef={inputRef}
            className="mt-2 inputs-fields"
            id="outlined-multiline-static"
            label="Write a comment…"
            multiline
            rows={3}
            name="description"
            value={state}
            variant="outlined"
            style={{ width: "100%" }}
            // required={true}
            onChange={(e) => handleChange(e)}
            autoFocus
          />
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="text-start mt-3 mb-2 event-chat-emoji-picker-button">
                <img
                  src={emoji_picker_icon}
                  onClick={() => {
                    setShowEmojiPicker((prevState) => !prevState);
                  }}
                />
              </div>
              {previews || oldImage ? (
                <span className="preview-comment-image mt-2 ms-2">
                  <span onClick={handleRemove}>x</span>
                  {previews ? (
                    <img src={previews} />
                  ) : (
                    <img src={s3baseUrl + oldImage} />
                  )}
                </span>
              ) : (
                <>
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="message-chat-upload-button"
                    style={{ display: "none" }}
                    name=""
                    onChange={handleUpload}
                  />
                  <label>
                    <div
                      onClick={() => {
                        document.getElementById("message-chat-upload-button") &&
                          document
                            .getElementById("message-chat-upload-button")
                            .click();
                      }}
                      className="event-chat-emoji-picker-button mb-2  mt-3"
                      style={{ marginLeft: "10px" }}>
                      <img src={upload_image} alt="" srcSet="" />
                    </div>
                  </label>
                </>
              )}
            </div>
            <div className="text-end mt-3">
              {formAction === "ADD" && (
                <button className="comment-submit-button" disabled={isLoading}>
                  {isLoading ? "Saving..." : "Post Comment"}
                </button>
              )}
              {formAction === "EDIT" && (
                <div className="d-flex justify-content-end">
                  <button
                    className="me-2 comment-submit-button"
                    onClick={handleCancelUpdate}>
                    Cancel
                  </button>
                  <button
                    className="comment-submit-button"
                    type="submit"
                    disabled={isLoading}>
                    {isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              )}
            </div>
          </div>
          {showEmojiPicker && (
            <div className="col-12 mt-4 mt-lg-0 ms-auto">
              <EmojiPicker inputRef={inputRef} setInput={setState} />
            </div>
          )}
        </form>
      </div>
    </>
  );
}
