import { Icon } from "@iconify/react";
import appleFilled from "@iconify/icons-ant-design/apple-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Link } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { get_root_value } from "src/utils/domUtils";
import { Link as RouterLink } from "react-router-dom";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: get_root_value("--portal-theme-primary"),
  // backgroundColor: "white",
  boxShadow: "0px 3px 6px 0px rgba(140, 149, 159, 0.15)",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: get_root_value("--portal-theme-primary"),
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.04)",

  // backgroundImage: `linear-gradient(135deg, ${alpha(
  //   get_root_value("--portal-theme-primary"),
  //   0
  // )} 0%, ${alpha(get_root_value("--portal-theme-primary"), 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

const TOTAL = 131;

export default function AppTotalCoins({
  title,
  count,
  icon,
  color,
  color2,
  sx,
  ...other
}) {
  console.log(title, "falkjdklsjfa", count);
  return (
    <RootStyle sx={{ backgroundColor: () => color2() + "!important" }}>
      <IconWrapperStyle sx={{ backgroundImage: () => color() + "!important" }}>
        {/* <AssignmentIcon /> */}
        <Icon icon={icon} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(count)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
      <Link
        component={RouterLink}
        variant="subtitle2"
        to={`/dashboard-coins-info`}
      >
        History!
      </Link>
    </RootStyle>
  );
}
