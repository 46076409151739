import {
  Avatar,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Box,
  Typography,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { makeStyles } from "@mui/styles";

import { s3baseUrl } from "src/config/config";

// import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";

import ConfirmationWithDescription from "src/components/ConfirmationWithDescription";

import ParameterModel from "./component/ParameterModel";
import { Icon } from "@iconify/react";
// import ExerciseHistoryModel from "./components/ExerciseHistoryModel";
// import { programme_workout_exercise_history_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import ToshowExercise from "./component/modalToshowExercise";
import { htmlDecode } from "src/utils/convertHtml";
import ExerciseHistoryModel from "./component/ExerciseHistoryModel";
import { programme_workout_exercise_history_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import ModalToShowAnExerciseInfo from "./component/ModalToShowAnExerciseInfo";
import CongratulationAnimation from "./component/CongratulationAnimation";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "fixed",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const AMRAPExercisesUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  isHistory,
  memberId,
  mainindex,
  isWorkoutCompleted,
  setIsWorkoutCompleted,
  memberRewardData,
  practiceType,
  handleSubmit,
}) => {
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [exerInfo, setExsrInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForSuperset, setModalOpenForSuperset] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [congratulation, setCongratulation] = useState(false);
  const [openExerciseAlert, setOpenExerciseAlert] = useState(false);

  const [historyModalOpenSuperset, setHistoryModalOpenSuperset] =
    useState(false);
  const [openSingleExerciseInfo, setOpenSingleExerciseInfo] = useState(false);
  const [singleExerciseInfo, setSingleExerciseInfo] = useState("");
  const [historyModalData, setHistoryModalData] = useState([]);
  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [alterIndex, setalterIndex] = useState();
  const [intervalTime, setIntervalTime] = useState(0);
  const [intervalSeconds, setIntervalSeconds] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");
  const [outerIndex, setOuterIndex] = useState("");

  const [openDetails, setOpenDetails] = useState(false);

  const [isHovered, setIsHovered] = useState("");
  const [isHoveredExercise, setIsHoveredExercise] = useState("");
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const handleChangeComplete = (e, index) => {
    const { checked } = e.target;
    // if (original[index]["is_completed"]) {
    //   return;
    // }
    const list = [...addArray];
    if (!checked && isWorkoutCompleted) {
      setExerIndex(index);
      setOpenExerciseAlert(true);
      return;
    }
    list[index]["is_completed"] = checked;

    setModalChangeExe(true);
    setaddArray(list);
    if (checked) setCongratulation(true);
  };
  const handleAgreeInExerciseSet = () => {
    const list = [...addArray];
    list[exerIndex]["is_completed"] = false;
    setIsWorkoutCompleted(false);
    setModalChangeExe(true);
    setOpenExerciseAlert(false);
    setaddArray(list);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (minutes, seconds) => {
    const totalSeconds = minutes * 60 + seconds;
    setIntervalTime(totalSeconds);
    const list = [...addArray];

    list[mainindex]["time_interval"] = totalSeconds;

    setaddArray(list);
  };
  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };

  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleArrowSuperSet = (value, index, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["open"] = value;
    // list[mainindex]["superSet"][exerIndex]["open"] = value;

    setaddArray(list);
  };

  const handleChangeEmomSet = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeEmomSetTempo = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
        value;
      setaddArray(list);
      setModalChangeExe(true);
    }
  };

  const handleRearageSetCount = (index, count) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = count;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpenSuperset(false);
  };

  const handleChangeCompletedRounds = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleSelectExercise = (value) => {
    const list = [...addArray];
    if (list[detailsData]["exercise"]._id == value._id) {
      return;
    }
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;

    setaddArray(list);
    setModalChangeExe(false);
    // if (isHistory) handleHistory(value, detailsData);
  };
  const handleCopyExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["set"] = value.set;
    list[detailsData]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpen(false);
  };
  const handleCopySupersetExercise = (value) => {
    const list = [...addArray];
    console.log(list[detailsData], "dskjfjkdshafkja");
    list[detailsData]["superSet"][exerIndex]["set"] = value.set;
    list[detailsData]["superSet"][exerIndex]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);

    handleRearageSetCount(detailsData, list[detailsData].superset_sets);
  };
  const handleSelectSupersetExercise = (value) => {
    const list = [...addArray];

    // return;
    // console.log(
    //   list[mainindex]["emomSets"][detailsData][exerIndex]["exercise"],
    //   "dsfkjhksjdhfja",
    //   list[mainindex]["emomSets"][detailsData],
    //   value
    // );

    list[mainindex]["emomSets"][detailsData][exerIndex]["exercise"] = value;
    list[mainindex]["emomSets"][detailsData][exerIndex]["description"] =
      value?.instructions;
    setaddArray(list);
    setModalChangeExe(true);
    // if (isHistory) handleHistorySuperset(value, detailsData, exerIndex);
  };

  const handleHistorySupersetOpen = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    // setHistoryModalData(value.history);
    handleHistorySuperset(value.exercise, mainIndex, exerInde);
  };
  const handleHistorySuperset = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };
    setHistoryModalData([]);
    setIsLoading(true);
    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[mainindex]["emomSets"][detailsData][exerIndex]["history"];
          // delete list[mainIndex]["superSet"][exerInde]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                mhr: item1?.mhr,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low ? item1?.pace_low : 0,
                paceUp: item1?.pace_up ? item1?.pace_up : 423,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "KM Pace", key: "c_km" },
                        { title: "MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });
        console.log(givenExercise, "kjdshakfjsdfhaj");
        setHistoryModalData(givenExercise);
        // setIsLoading(false);
        // setHistoryModalOpenSuperset(true);
        const list = [...addArray];
        list[mainindex]["emomSets"][detailsData][exerIndex]["history"] =
          givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setHistoryModalOpenSuperset(true);
    }
  };

  const handleChangeParametersInsetFieldValueSuperSet = (
    value,
    outerIndex,
    exerIndex
  ) => {
    const items = Array.from(addArray);

    const sets = items[mainindex].emomSets[outerIndex][exerIndex].set.map(
      (item) => {
        return {
          time: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Duration/Pace")
            ? item?.time
            : "",
          weight: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Weight")
            ? item?.weight
            : "",
          weightType: item?.weightType,
          reps: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Reps")
            ? item?.reps
            : "",
          rest: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Rest")
            ? item?.rest
            : "",
          distance: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Distance/Calories")
            ? item?.distance
            : "",
          distanceType: item?.distanceType,
          timeType: item?.timeType,
          restType: item?.restType,
          tempo: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Tempo")
            ? item?.tempo
            : "",
        };
      }
    );
    // setExsrInfo(items[outerIndex].superSet[exerIndex].parameters);
    items[mainindex].emomSets[outerIndex][exerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInsetFieldValue = (value, outerIndex) => {
    const items = Array.from(addArray);
    const sets = items[outerIndex].set.map((item) => {
      return {
        time: items[outerIndex].parameters.includes("Duration/Pace")
          ? item?.time
          : "",
        weight: items[outerIndex].parameters.includes("Weight")
          ? item?.weight
          : "",
        weightType: item?.weightType,
        reps: items[outerIndex].parameters.includes("Reps") ? item?.reps : "",
        rest: items[outerIndex].parameters.includes("Rest") ? item?.rest : "",
        distance: items[outerIndex].parameters.includes("Distance/Calories")
          ? item?.distance
          : "",
        distanceType: item?.distanceType,
        timeType: item?.timeType,
        restType: item?.restType,
        tempo: items[outerIndex].parameters.includes("Tempo")
          ? item?.tempo
          : "",
      };
    });
    console.log(items[outerIndex], "sdkfjklsdjfjlkakj");
    // setExsrInfo(items[outerIndex].parameters);
    items[outerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInset = (value) => {
    const items = Array.from(addArray);
    if (modalOpenForSuperset) {
      items[mainindex].emomSets[outerIndex][exerIndex].parameters = value;
      setExsrInfo(items[mainindex].emomSets[outerIndex][exerIndex].parameters);
      setaddArray(items);
      setModalChangeExe(true);
      handleChangeParametersInsetFieldValueSuperSet(
        value,
        outerIndex,
        exerIndex
      );
      return;
    }
    items[outerIndex].parameters = value;
    setExsrInfo(items[outerIndex].parameters);
    setaddArray(items);
    setModalChangeExe(true);
    handleChangeParametersInsetFieldValue(value, outerIndex);
  };

  useEffect(() => {
    if (!addArray[mainindex].last_amrap_exercise) {
      const list = [...addArray];
      list[mainindex].last_amrap_exercise =
        addArray[mainindex].emomSets[0][0].exercise._id;

      setaddArray(list);
      // setModalChangeExe(true);
    }
  }, []);
  const getKmhrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = (value / time) * 3.6;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6 * 1.609;
    } else if (type == "km") {
      result = ((1 * 1000) / time) * 3.6;
    }
    return result.toFixed(2);
  };
  const getPaceTextinPaces = (type) => {
    let result = "";
    if (type == "m") {
      result = "Time Cap";
    } else if (type == "mi") {
      result = "Pace Min/Mile";
    } else if (type == "km") {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getMihrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = result = ((value / time) * 3.6) / 1.609;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6;
    } else if (type == "km") {
      result = (((1 * 1000) / time) * 3.6) / 1.609;
    }
    return result.toFixed(2);
  };
  const handleMinutesChangeSets = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    const minutes = parseInt(e.target.value) || 0;
    const seconds =
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] % 60;
    updateIntervalTimeSets(minutes, seconds, index, exerIndex, setIndex, name);
  };

  const handleSecondsChangeSets = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;
    const list = [...addArray];

    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] / 60
    );
    updateIntervalTimeSets(minutes, seconds, index, exerIndex, setIndex, name);
  };

  // Function to update intervalTime state
  const updateIntervalTimeSets = (
    minutes,
    seconds,
    index,
    exerIndex,
    setIndex,
    name
  ) => {
    const totalSeconds = minutes * 60 + seconds;
    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      totalSeconds;
    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleClickOpen = async (exercise) => {
    setSingleExerciseInfo(exercise);
    setOpenSingleExerciseInfo(true);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (congratulation) {
        setCongratulation(false);
        handleSubmit(true); // Update the state after 3 seconds
      }
    }, 2500);

    return () => clearTimeout(timeout); // Clean up the timeout to avoid memory leaks
  }, [congratulation]);
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      {congratulation && <CongratulationAnimation />}

      <ConfirmationWithDescription
        open={openSuperSetAlter}
        setOpen={setOpenSuperSetAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeInSuperSet}
      />
      <ConfirmationWithDescription
        open={openExerciseAlert}
        setOpen={setOpenExerciseAlert}
        title={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "Opps!" :
          "Are you sure you want to take this action?"
        }
        descriotion={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "You are not able to uncomplete because you have insufficient points/coins." :
          `If you uncheck, your points or coins allocated for this ${practiceType} will be deducted.`
        }
        isJustForOk={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? true :
             false
        }
        handleAgree={handleAgreeInExerciseSet}
      />
      <ModalToShowAnExerciseInfo
        open={openSingleExerciseInfo}
        setOpen={setOpenSingleExerciseInfo}
        Id={singleExerciseInfo}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={handleSelectSupersetExercise}
      />
      <ParameterModel
        open={modalOpen}
        setOpen={setModalOpen}
        data={exerInfo}
        handleChange={handleChangeParametersInset}
      />
      <ExerciseHistoryModel
        open={historyModalOpen}
        setOpen={setHistoryModalOpen}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={handleCopyExercise}
      />
      <ExerciseHistoryModel
        open={historyModalOpenSuperset}
        setOpen={setHistoryModalOpenSuperset}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={handleCopySupersetExercise}
      />
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{ position: "relative" }}
              className="workout-Superset-card my-1">
              {/* <SuperSets length={data?.superset_sets} data={data} /> */}
              <Box>
                <div className="d-flex align-items-start justify-content-center mb-3">
                  {/* <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Interval Duration"
                    label="Interval Duration"
                    InputLabelProps={{className:"textfiels-input-label",
                      shrink: true,
                      style: { fontSize: "14px" },
                    }}
                    InputProps={{className:"textfiels-input-value",
                      style: {
                        fontSize: "13px",
                        paddingLeft: "0px",
                        paddingRight: "0px",

                        justifyContent: "center",
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <TextField
                            id="outlined-basic-minutes"
                            type="number"
                            size="small"
                            placeholder="Min"
                            value={formatWithLeadingZeros(
                              Math.floor(addArray[mainindex].time_interval / 60)
                            )}
                            onChange={handleMinutesChange}
                            InputProps={{className:"textfiels-input-value",
                              style: {
                                fontSize: "19px",
                                fontWeight: "500",
                                // width: "40px",
                              },
                            }}
                            sx={{
                              "& fieldset": {
                                border: "none",
                              },
                              "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  padding: "0px",
                                },
                              "& input": {
                                textAlign: "center",
                              },
                              width: "100%",
                            }}
                          />
                          <div className="fs-4 fw-semibold ">:</div>

                          <TextField
                            id="outlined-basic-seconds"
                            type="number"
                            size="small"
                            placeholder="Sec"
                            value={formatWithLeadingZeros(
                              addArray[mainindex].time_interval % 60
                            )}
                            onChange={handleSecondsChange}
                            InputProps={{className:"textfiels-input-value",
                              style: {
                                fontSize: "19px",
                                fontWeight: "500",
                                // width: "40px",
                              },
                            }}
                            sx={{
                              "& fieldset": {
                                border: "none",
                              },
                              "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  padding: "0px",
                                },
                              "& input": {
                                textAlign: "center",
                              },
                              width: "100%",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      borderRadius: "5px",
                      fontSize: "13px",
                      "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          width: "0px",
                          padding: "9px 0px",
                        },

                      width: "110px",
                    }}
                  /> */}
                  <div className="d-flex align-items-center justify-content-center  my-2">
                    <div className="text-center">
                      As Many Rounds and Reps as possible in{" "}
                      {formatWithLeadingZeros(
                        Math.floor(addArray[mainindex].time_interval / 60)
                      )}
                      :
                      {formatWithLeadingZeros(
                        addArray[mainindex].time_interval % 60
                      )}{" "}
                      minutes
                    </div>
                  </div>
                  {/* <div className=" cross-icon ">
             
                    <>
                      <Tooltip title="Remove">
                        <RemoveCircleOutlineIcon
                          onClick={() => handleDelete(mainindex)}
                          className="diary-icon-remove"
                        />
                      </Tooltip>
                    </>
       
                    <Tooltip title="Add">
                      <AddCircleOutlineIcon
                        onClick={() => handleAdd(mainindex)}
                        className="diary-icon-add"
                      />
                    </Tooltip>

                    <CustomPopoverSection
                      menu={memueOptions(mainindex)}
                      data={mainindex}
                    />
                  </div> */}
                </div>

                {addArray[mainindex].emomSets.length > 0 &&
                  addArray[mainindex].emomSets.map((item, index) => {
                    return (
                      <div className=" my-2 ">
                        <div className="d-flex justify-content-between align-items-center ">
                          <div className="fs-5">Movements</div>
                        </div>

                        {item.length > 0 &&
                          item.map((exerData, exerIndex) => {
                            return (
                              <div className="ms-4 my-1">
                                <div
                                  className="d-flex workout-set-card  "
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                  }}>
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "100%",
                                    }}>
                                    <div
                                      className="row  "
                                      onMouseEnter={() => handleMouseLeave()}>
                                      <div
                                        className={`col-sm-12 ${"col-md-6"} col-lg-6 d-flex align-items-center p-0 mt-2 mt-md-0 mt-lg-0 `}>
                                        <div className="exercise-label-box">
                                          <span className="outer-label">
                                            Exercise
                                          </span>
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div
                                              className="exercise-label w-100"
                                              style={{ cursor: "default" }}>
                                              <div className="d-flex align-items-center">
                                                {exerData?.exercise?.image
                                                  ?.thumbnail_3 && (
                                                  <Avatar
                                                    sx={{ borderRadius: "5px" }}
                                                    alt="User Image"
                                                    src={
                                                      s3baseUrl +
                                                      exerData?.exercise?.image
                                                        ?.thumbnail_3
                                                    }
                                                  />
                                                )}
                                                <div className="label-text ms-1">
                                                  {exerData?.exercise?.title
                                                    ? exerData?.exercise?.title
                                                    : ""}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="exercise-action">
                                              <div style={{ fontSize: "16px" }}>
                                                {exerData?.exercise ? (
                                                  <div>
                                                    <InfoIcon
                                                      onClick={() =>
                                                        handleClickOpen(
                                                          exerData?.exercise
                                                            ?._id
                                                        )
                                                      }
                                                      className="d-block"
                                                      fontSize="14px"
                                                      sx={{
                                                        opacity: 0.5,
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          placeholder="Exercise"
                                          value={exerData?.exercise?.title}
                                          name="Exercise"
                                          // onClick={() =>
                                          //   handleSupersetExerciseList(
                                          //     index,
                                          //     exerIndex
                                          //   )
                                          // }
                                          // onChange={(e) => {}}
                                          InputLabelProps={{className:"textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          label="Exercise"
                                          InputProps={{className:"textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingLeft: "6px",
                                            },
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                {exerData?.exercise?.image
                                                  ?.thumbnail_3 && (
                                                  <Avatar
                                                    sx={{ borderRadius: "5px" }}
                                                    alt="User Image"
                                                    src={
                                                      s3baseUrl +
                                                      exerData?.exercise?.image
                                                        ?.thumbnail_3
                                                    }
                                                  />
                                                )}
                                              </InputAdornment>
                                            ),
                                            // endAdornment: (
                                            //   <InputAdornment position="end">
                                            //     <div
                                            //       style={{ fontSize: "16px" }}
                                            //     >
                                            //       {exerData?.exercise ? (
                                            //         <EditIcon
                                            //           fontSize="12px"
                                            //           sx={{ opacity: 0.5 }}
                                            //         />
                                            //       ) : (
                                            //         <AddIcon
                                            //           sx={{ opacity: 0.5 }}
                                            //         />
                                            //       )}
                                            //     </div>
                                            //   </InputAdornment>
                                            // ),
                                            inputProps: {
                                              readOnly: true, // Disable editing
                                            },
                                          }}
                                          sx={{
                                            borderRadius: "5px",

                                            fontSize: "13px",
                                            width: "100%",
                                          }}
                                        /> */}
                                        <Button
                                          sx={{ textTransform: "inherit" }}
                                          size="small"
                                          color="primary"
                                          variant="contained"
                                          className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                          onClick={() => {
                                            handleArrowSuperSet(
                                              !exerData?.open ? true : false,
                                              index,
                                              exerIndex
                                            );
                                          }}>
                                          {!exerData?.open ? (
                                            <KeyboardArrowDownIcon
                                              sx={{
                                                cursor: "pointer",
                                                // opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowUpIcon
                                              sx={{
                                                cursor: "pointer",
                                                // opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                            />
                                          )}

                                          <Typography
                                            variant="body2"
                                            sx={{
                                              // opacity: 0.6,
                                              whiteSpace: "nowrap",
                                            }}>
                                            {` Sets ${1}`}
                                          </Typography>
                                        </Button>
                                      </div>

                                      <div
                                        className={`col-sm-12 ${"col-md-6"} col-lg-6 p-0 mt-2 mt-md-0 mt-lg-0 d-flex align-items-center justify-content-start `}>
                                        <div className="d-flex align-items-center mx-1">
                                          {exerData?.exercise && (
                                            <Button
                                              sx={{ textTransform: "inherit" }}
                                              size="small"
                                              color="primary"
                                              variant="contained"
                                              className="d-flex align-items-center ms-2 small-contained-dialog-Button"
                                              onClick={() => {
                                                handleHistorySupersetOpen(
                                                  exerData,
                                                  index,
                                                  exerIndex
                                                );
                                              }}>
                                              <Icon
                                                icon="fa-solid:history"
                                                fontSize="10px"
                                              />
                                              <Typography
                                                variant="body2"
                                                sx={{ ml: 0.5 }}>
                                                {`History`}
                                              </Typography>
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {exerData?.open &&
                                      exerData?.set?.map((item, setIndex) => (
                                        <div className="d-flex align-items-center my-3  workout-card-inset">
                                          <div className="row w-100">
                                            {exerData.parameters.includes(
                                              "Pace"
                                            ) && (
                                              <>
                                                <div
                                                  className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}>
                                                  <FormControl
                                                    readOnly
                                                    fullWidth>
                                                    <InputLabel
                                                      className="set-Type-lable"
                                                      sx={{
                                                        fontSize: "14px",
                                                      }}
                                                      id="demo-simple-select-label">
                                                      Type
                                                    </InputLabel>

                                                    <Select
                                                      className="set-Type-value"
                                                      id="select-option"
                                                      variant="outlined"
                                                      size="small"
                                                      name="race_type"
                                                      value={item?.race_type}
                                                      label="Type"
                                                      readOnly="true"
                                                      inputProps={{
                                                        style: {
                                                          padding: 0,
                                                        },
                                                      }}
                                                      onChange={(e) => {
                                                        handleChangeEmomSet(
                                                          e,
                                                          index,
                                                          exerIndex,
                                                          setIndex
                                                        );
                                                      }}
                                                      sx={{
                                                        width: "100%",
                                                        fontSize: "13px",
                                                        "& .MuiOutlinedInput-input":
                                                          {
                                                            paddingLeft: 1,
                                                          },
                                                      }}>
                                                      <MenuItem value="el">
                                                        Easy/Long
                                                      </MenuItem>
                                                      <MenuItem value="c">
                                                        Compromised
                                                      </MenuItem>

                                                      <MenuItem value="i">
                                                        Interval
                                                      </MenuItem>
                                                      <MenuItem value="r">
                                                        Repitition
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </div>
                                                <div
                                                  className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}>
                                                  <TextField
                                                    sx={{
                                                      borderRadius: "5px",
                                                      paddingRight: 0,
                                                      width: "100%",
                                                    }}
                                                    readOnly
                                                    size="small"
                                                    id="custom-input"
                                                    variant="outlined"
                                                    placeholder={"Distance"}
                                                    name="distance"
                                                    label="Distance"
                                                    value={item?.distance}
                                                    onChange={(e) => {
                                                      handleChangeEmomSet(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    type="number"
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="distanceType"
                                                            value={
                                                              item?.distanceType
                                                            }
                                                            label="Weight Type"
                                                            readOnly={true}
                                                            onChange={(e) => {
                                                              handleChangeEmomSet(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                            }}>
                                                            <MenuItem value="m">
                                                              Meter
                                                            </MenuItem>
                                                            <MenuItem value="km">
                                                              KM
                                                            </MenuItem>
                                                            <MenuItem value="mi">
                                                              Mile
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  className={`col-12 col-md-2 col-lg-2 pe-0 py-2`}>
                                                  <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    label={getPaceTextinPaces(
                                                      item?.distanceType
                                                    )}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    readOnly={true}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingLeft: "0px",
                                                        paddingRight: "0px",

                                                        justifyContent:
                                                          "center",
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position="end"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}>
                                                          <TextField
                                                            id="outlined-basic-minutes"
                                                            type="number"
                                                            size="small"
                                                            placeholder="Min"
                                                            name="pace"
                                                            value={formatWithLeadingZeros(
                                                              Math.floor(
                                                                item?.pace / 60
                                                              )
                                                            )}
                                                            readOnly={true}
                                                            onChange={(e) =>
                                                              handleMinutesChangeSets(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              )
                                                            }
                                                            InputProps={{
                                                              className:
                                                                "textfiels-input-value",
                                                              style: {
                                                                fontSize:
                                                                  "14px",
                                                              },
                                                              readOnly: true,
                                                            }}
                                                            sx={{
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& input": {
                                                                textAlign:
                                                                  "center",
                                                                padding: "0px",
                                                              },
                                                              width: "100%",
                                                            }}
                                                          />
                                                          <div className="fs-6 fw-semibold ms-1 ">
                                                            :
                                                          </div>

                                                          <TextField
                                                            id="outlined-basic-seconds"
                                                            type="number"
                                                            size="small"
                                                            placeholder="Sec"
                                                            name="pace"
                                                            value={formatWithLeadingZeros(
                                                              item?.pace % 60
                                                            )}
                                                            onChange={(e) =>
                                                              handleSecondsChangeSets(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              )
                                                            }
                                                            InputProps={{
                                                              className:
                                                                "textfiels-input-value",
                                                              style: {
                                                                fontSize:
                                                                  "14px",
                                                              },
                                                              readOnly: true,
                                                            }}
                                                            sx={{
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& input": {
                                                                textAlign:
                                                                  "center",
                                                                padding: "0px",
                                                              },
                                                              width: "100%",
                                                            }}
                                                          />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                        {
                                                          width:
                                                            "0px !important",
                                                          padding:
                                                            "9px 0px !important",
                                                        },
                                                      "& .css-1nuss9t": {
                                                        width: "0px !important",
                                                        padding:
                                                          "9px 0px !important",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    bottom: "4px",
                                                    left: "5px",
                                                  }}>
                                                  <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                      opacity: 0.6,
                                                      fontWeight: "600",
                                                      fontSize: "12px",
                                                    }}>{` @ Speed ${getKmhrForPace(
                                                    item?.distance,
                                                    item?.pace,
                                                    item?.distanceType
                                                  )} km/hr or ${getMihrForPace(
                                                    item?.distance,
                                                    item?.pace,
                                                    item?.distanceType
                                                  )} mi/hr`}</Typography>
                                                </div>
                                              </>
                                            )}
                                            {(exerData.parameters.includes(
                                              "Duration/Pace"
                                            ) ||
                                              exerData.parameters.includes(
                                                "Time"
                                              )) && (
                                              <div
                                                className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}>
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.time}
                                                  name="time"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label={
                                                    exerData.parameters.includes(
                                                      "Duration/Pace"
                                                    )
                                                      ? "Duration/Pace"
                                                      : "Time"
                                                  }
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="timeType"
                                                          value={item?.timeType}
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}

                                            {exerData.parameters.includes(
                                              "Reps"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Reps"
                                                  type="number"
                                                  label="Reps"
                                                  value={item?.reps}
                                                  name="reps"
                                                  readOnly
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Height"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  readOnly="true"
                                                  value={item?.height}
                                                  name="height"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Height"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="heightType"
                                                          value={
                                                            item?.heightType
                                                          }
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="cm">
                                                            Cm
                                                          </MenuItem>
                                                          <MenuItem value="ft">
                                                            Feet
                                                          </MenuItem>
                                                          <MenuItem value="inch">
                                                            Inch
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Weight"
                                            ) && (
                                              <div className="col-12 col-md-4 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    paddingRight: 0,
                                                    width: "100%",
                                                  }}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder="Weight"
                                                  name="weight"
                                                  label="Weight"
                                                  readOnly="true"
                                                  value={item.weight}
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="weightType"
                                                          value={
                                                            item?.weightType
                                                          }
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="1rm">
                                                            % 1RM
                                                          </MenuItem>
                                                          <MenuItem value="body_weight">
                                                            % BW
                                                          </MenuItem>
                                                          <MenuItem value="kg">
                                                            KG
                                                          </MenuItem>
                                                          <MenuItem value="lb">
                                                            Pound
                                                          </MenuItem>
                                                          <MenuItem value="poods">
                                                            Poods
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Distance/Calories"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    paddingRight: 0,
                                                    width: "100%",
                                                  }}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder={
                                                    item?.distanceType ==
                                                    "calories"
                                                      ? "Calories"
                                                      : "Distance"
                                                  }
                                                  name="distance"
                                                  label="Distance/Calories"
                                                  value={item?.distance}
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="distanceType"
                                                          value={
                                                            item?.distanceType
                                                          }
                                                          label="Weight Type"
                                                          readOnly={true}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="m">
                                                            Meter
                                                          </MenuItem>
                                                          <MenuItem value="km">
                                                            KM
                                                          </MenuItem>
                                                          <MenuItem value="mi">
                                                            Mile
                                                          </MenuItem>
                                                          <MenuItem value="calories">
                                                            Calories
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "%MHR"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="%MHR"
                                                  type="number"
                                                  label="%MHR"
                                                  readOnly="true"
                                                  value={item?.mhr}
                                                  name="mhr"
                                                  onChange={(e) => {
                                                    handleChangeEmomSetTempo(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",

                                                    fontSize: "13px",
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Tempo"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Tempo"
                                                  type="number"
                                                  label="Tempo"
                                                  readOnly="true"
                                                  value={item?.tempo}
                                                  name="tempo"
                                                  onChange={(e) => {
                                                    handleChangeEmomSetTempo(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Rest"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  readOnly="true"
                                                  value={item?.rest}
                                                  name="rest"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Rest"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="restType"
                                                          value={item?.restType}
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </Box>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                <TextField
                  id="outlined-basic"
                  // label="Exercise"
                  size="small"
                  variant="outlined"
                  placeholder="Instructions"
                  label="Instructions"
                  type="number"
                  value={addArray[mainindex].description}
                  name="description"
                  multiline
                  rows={addArray[mainindex].description ? null : 2}
                  // onChange={(e) => handleChangeDes(e, mainindex)}
                  InputLabelProps={{
                    className: "textfiels-input-label",
                    shrink: true,
                    style: { fontSize: "14px" },
                  }}
                  InputProps={{
                    className: "textfiels-input-value",
                    style: { fontSize: "14px" },
                    readOnly: true,
                  }}
                  sx={{
                    borderRadius: "5px",
                    // "& legend": { display: "none" },
                    // "& fieldset": { top: 0 },

                    mt: 2,
                    width: "100%",
                  }}
                />
                <div className="row">
                  <div className=" col-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-start justify-content-md-center justify-content-lg-end mt-3 ">
                    <div style={{ whiteSpace: "nowrap" }}>
                      Full Rounds Completed
                    </div>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      placeholder=""
                      type="number"
                      value={addArray[mainindex].completedRounds}
                      name="completedRounds"
                      onChange={(e) =>
                        handleChangeCompletedRounds(e, mainindex)
                      }
                      InputLabelProps={{
                        className: "textfiels-input-label",
                        shrink: true,
                        style: { fontSize: "14px" },
                      }}
                      //   label="Set"
                      InputProps={{
                        className: "textfiels-input-value",
                        style: { fontSize: "13px", padding: "0" },
                      }}
                      sx={{
                        mx: 1,
                        borderRadius: "5px",
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        fontSize: "13px",
                        "& input": {
                          padding: "3px 5px",
                          textAlign: "center",
                        },
                        width: "33px",
                      }}
                    />{" "}
                    ,
                  </div>
                  <div className=" col-12 col-md-12 col-lg-9 d-flex align-items-center justify-content-center justify-content-lg-start ">
                    <div className="row w-100">
                      <div className="col-10 col-md-7 col-lg-6 mt-3 d-flex align-items-center justify-content-center  p-md-0">
                        <div className="">
                          How far did you get on your last round?
                          <TextField
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            placeholder=""
                            type="number"
                            value={
                              addArray[mainindex].last_amrap_exercise_rounds
                            }
                            name="last_amrap_exercise_rounds"
                            onChange={(e) =>
                              handleChangeCompletedRounds(e, mainindex)
                            }
                            InputLabelProps={{
                              className: "textfiels-input-label",
                              shrink: true,
                              style: { fontSize: "14px" },
                            }}
                            //   label="Set"
                            InputProps={{
                              className: "textfiels-input-value",
                              style: { fontSize: "13px", padding: "0" },
                            }}
                            sx={{
                              ml: 1,
                              borderRadius: "5px",
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                              fontSize: "13px",
                              "& input": {
                                padding: "3px 5px",
                                textAlign: "center",
                              },
                              width: "33px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col-lg-6 mt-3 d-flex align-items-center justify-content-start justify-content-lg-start">
                        <div className="me-2">of </div>

                        {addArray[mainindex]?.last_amrap_exercise && (
                          <FormControl className="me-4">
                            <InputLabel
                              id="demo-simple-select-label"
                              shrink={true}>
                              Exercise
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="last_amrap_exercise"
                              value={
                                addArray[mainindex]?.last_amrap_exercise &&
                                addArray[mainindex]?.last_amrap_exercise
                              }
                              label="Exercise"
                              onChange={(e) =>
                                handleChangeCompletedRounds(e, mainindex)
                              }
                              InputLabelProps={{
                                className: "textfiels-input-label",
                                shrink: true,
                                style: { fontSize: "14px" },
                              }}
                              InputProps={{
                                className: "textfiels-input-value",
                                style: { fontSize: "13px", padding: "0" },
                              }}
                              sx={{
                                fontSize: "13px",

                                "& .MuiOutlinedInput-input": {
                                  padding: "3px 5px",
                                  // textAlign: "center",
                                },
                                width: "100%",
                              }}>
                              {addArray[mainindex]?.emomSets[0]?.map(
                                (exerData, i) => {
                                  return (
                                    <MenuItem
                                      key={i}
                                      value={exerData?.exercise?._id}>
                                      {exerData?.exercise?.title}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ p: "3px" }}
                            checked={addArray[mainindex].is_completed}
                            onChange={(e) => handleChangeComplete(e, mainindex)}
                          />
                        }
                        label="Mark as Complete"
                      />
                    </FormGroup>
                  </div>
                </div>
              </Box>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AMRAPExercisesUI;
