import faker from "faker";
import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { set, sub, formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
import CircleIcon from "@mui/icons-material/Circle";

// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { mockImgAvatar } from "../../utils/mockImages";
// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import moment from "moment";
import {
  addGroup,
  approved,
  exit,
  group,
  newPost,
  pending,
  reject,
  communityHeart,
  heart,
  comment,
  coinNotification,
} from "src/assets";
import { markAllAsRead } from "src/DAL/Notification/Notification";
// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2" sx={{ fontSize: "0.75rem" }}>
      {notification.title}
      {/* <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {noCase(notification.description)}
      </Typography> */}
    </Typography>
  );

  if (notification.notification_type === "feed_like") {
    return {
      avatar: <img alt={notification.title} src={heart} />,
      title,
    };
  }
  if (notification.notification_type === "feed_comment_add") {
    return {
      avatar: <img alt={notification.title} src={comment} />,
      title,
    };
  }
  if (notification.notification_type === "feed_coins_share") {
    return {
      avatar: <img alt={notification.title} src={coinNotification} />,
      title,
    };
  }
  if (notification.notification_type === "member_left_group") {
    return {
      avatar: <img alt={notification.title} src={exit} />,
      title,
    };
  }
  if (notification.notification_type === "feed_added") {
    return {
      avatar: <img alt={notification.title} src={newPost} />,
      title,
    };
  }
  if (notification.notification_type === "feed_pending") {
    return {
      avatar: <img alt={notification.title} src={pending} />,
      title,
    };
  }
  if (notification.notification_type === "feed_approved") {
    return {
      avatar: <img alt={notification.title} src={approved} />,
      title,
    };
  }
  if (notification.notification_type === "feed_rejected") {
    return {
      avatar: <img alt={notification.title} src={reject} />,
      title,
    };
  }
  if (notification.notification_type === "member_removed_from_group") {
    return {
      avatar: <img alt={notification.title} src={group} />,
      title,
    };
  }
  if (notification.notification_type === "member_added_to_group") {
    return {
      avatar: <img alt={notification.title} src={addGroup} />,
      title,
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title,
  };
}

export default function NotificationsPopover({
  allNotifications,
  totalNotifications,
  setUnreadNotificationCount,
  unreadNotificationCount,
  setAllNotifications,
  handleRedirect,
  setOpen,
  open,
}) {
  const anchorRef = useRef(null);
  // const [open, setOpen] = useState(false);
  // const [notifications, setNotifications] = useState(NOTIFICATIONS);
  // const totalUnRead = notifications.filter(
  //   (item) => item.isUnRead === true
  // ).length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  NotificationItem.propTypes = {
    notification: PropTypes.object.isRequired,
  };
  function NotificationItem({ notification }) {
    const { avatar, title } = renderContent(notification);

    return (
      <ListItemButton
        to="#"
        disableGutters
        component={RouterLink}
        sx={{
          py: 1.5,
          px: 1.0,
          mt: "1px",
          pr: 1.4,

          ...(!notification.is_read && {
            bgcolor: "action.selected",
            color: "#8a8a8a",
          }),
        }}
        // className={`${
        //   notification.receivers.is_read === true ? "notification-item" : ""
        // }`}
        onClick={(e) => {
          e.preventDefault();
          handleRedirect(
            notification._id,
            notification.notification_type,
            notification
          );
        }}
      >
        {!notification.is_read && (
          <CircleIcon
            className={`notifications-circle ${
              notification.is_read ? "notifications-circle-seen" : "ml-5"
            }`}
          />
        )}

        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: "background.neutral",
              ...(notification.is_read && {
                ml: "12px",
              }),
            }}
          >
            {avatar}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{
            color: "#1b1b1b",
          }}
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <Box
                component={Icon}
                icon={clockFill}
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              {formatDistanceToNow(new Date(notification.createdAt))} ago
              {/* {moment(notification.createdAt, "YYYY-MM-DD HH:mm").fromNow()} */}
            </Typography>
          }
        />
      </ListItemButton>
    );
  }
  const handleMarkAllAsRead = () => {
    setUnreadNotificationCount(0);
    markAllAsRead(); //api to mark all read
    setAllNotifications(
      allNotifications.map((notification) => ({
        ...notification,
        is_read: true,
      }))
    );
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge badgeContent={unreadNotificationCount} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {unreadNotificationCount} unread notifications.
            </Typography>
          </Box>

          {unreadNotificationCount > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            // subheader={
            //   <ListSubheader
            //     disableSticky
            //     sx={{ py: 1, px: 2.5, typography: "overline" }}>
            //     New
            //   </ListSubheader>
            // }
          >
            {allNotifications.map((notification, index) => (
              <NotificationItem key={index} notification={notification} />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        {/* <Divider /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}
