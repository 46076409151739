import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { s3baseUrl } from "src/config/config";
import { groupsListing } from "src/DAL/Community/Community";
const useStyles = makeStyles(() => ({
  loading: {
    margin: "auto",
    // marginTop: "20%",
  },
}));
export default function GroupsFeeds() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const getGroupsListing = async () => {
    setIsLoading(true);
    const result = await groupsListing(0, 50, "");

    if (result.code == 200) {
      const groups = result?.community_groups?.map((group, index) => {
        return {
          ...group,
          id: group._id,
          title: group?.title,
          description: group?.description,
        };
      });
      setData(groups);
      setIsLoading(false);
    }
  };
  const handleGroupFeed = (value) => {
    navigate(`/group-feed/${value._id}`, {
      state: value,
    });
  };
  useEffect(() => {
    getGroupsListing();
  }, []);

  return (
    <>
      <div className="community-left-sidebar">
        <div
          className="row profile-cards"
          style={{ maxHeight: "500px", overflowY: "auto" }}>
          <div className="col-12">
            <h3 className="groups-text">Groups</h3>
          </div>
          {isLoading ? (
            <div className="loading-container">
              <CircularProgress className={classes.loading} color="primary" />
            </div>
          ) : (
            <>
              {data &&
                data.map((item, index) => {
                  return (
                    <div className="col-12 mb-2" key={index}>
                      <div
                        className="group-card"
                        onClick={() => handleGroupFeed(item)}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3  d-flex align-items-center">
                              <img
                                width={40}
                                height={40}
                                src={s3baseUrl + item?.image?.thumbnail_1}
                                alt="Group Image"
                                // className="img-fluid"
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                            <div className="col-8 d-flex align-items-center">
                              <p className="card-title groups-text-feeds">
                                {item.title}
                              </p>
                              {/* <p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}></div>
                          </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {data.length == 0 && (
                <>
                  {/* <img
                width="30px !important"
                height={30}
                alt="Groups"
                src={no_data}
              /> */}
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ marginBottom: 10, marginTop: 10 }}
                    justifyContent="center">
                    No Groups Available
                  </Typography>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
