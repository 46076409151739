import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import profileImg from "../../assets/images/profileImg.png";
import R from "../../assets/images/R.jpeg";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TimeZones } from "src/utils/constants";
import { EditProfileApi, memberDetail } from "src/DAL/Profile/Profile";
import { makeStyles } from "@mui/styles";
import { Autocomplete, CircularProgress } from "@mui/material";
import countryList from "react-select-country-list";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { FormHelperText } from "@mui/material";

import { profilePlaceholder } from "src/assets";
const Input = styled("input")({
  display: "none",
});
TimeZones.sort();
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

function UserInformation(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputValue, setInputValue] = React.useState("");
  const [image, setImage] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const handleChangeDate = (event) => {
    setMemberData((prevState) => {
      return {
        ...prevState,
        dob: event?.$d,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getMemberDetail = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      setMemberData(result.member);
      console.log(result.member, "result.member");
      setTimeZoneValue(result.member.time_zone);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    var dateString = moment(memberData.dob).format("YYYY-MM-DD");
    let [year, month, day] = [1, 1, 1];

    if (dateString !== "Invalid date") {
      [year, month, day] = dateString.split("-");
      console.log(day, month, year, "seperated year month day");
      if (year < 1900 || year > moment().year()) {
        enqueueSnackbar("Please select a valid year", { variant: "error" });
      } else {
        setIsLoading(true);
        const formData = new FormData();
        var dateString = moment(memberData.dob).format("YYYY-MM-DD");
        formData.append("first_name", memberData.first_name);
        formData.append("last_name", memberData.last_name);
        formData.append("street", memberData.street);
        formData.append("city", memberData.city);
        formData.append("zip_code", memberData.zip_code);
        formData.append("state", memberData.state);
        formData.append("country", memberData.country);
        formData.append("time_zone", timeZoneValue);
        formData.append("facebook_link", memberData.facebook_link);
        formData.append("website_link", memberData.website_link);
        formData.append("instagram_link", memberData.instagram_link);
        formData.append("linkedin_link", memberData.linkedin_link);
        formData.append("youtube_link", memberData.youtube_link);
        formData.append("member_biography", memberData.member_biography);
        formData.append("contact_number", memberData.contact_number);
        // formData.append(
        //   "nineteen_day_plan_currency",
        //   memberData.nineteen_day_plan_currency
        // );
        formData.append("dob", dateString);
        if (image) {
          formData.append("profile_image", image);
        }
        console.log(...formData);
        const result = await EditProfileApi(formData);
        if (result.code === 200) {
          localStorage.setItem(
            `user_data`,
            JSON.stringify(result.existingMember)
          );
          enqueueSnackbar(result.message, { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("Please select a valid date", { variant: "error" });
    }
  };
  const handleUpdate1 = async (e) => {
    e.preventDefault();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setMemberData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  useEffect(() => {
    // getMemberDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container ">
      <form onSubmit={handleUpdate1} autoComplete="off">
        <div className="mt-4 mb-4 d-flex justify-content-center">
          <div className="cards-edit p-4">
            <div className=" image d-flex flex-column justify-content-center align-items-center">
              <div className="row w-100 mt-3">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="mt-4">
                    <h3 className="quotes-heading">Your Information</h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl fullWidth>
                      <FormHelperText sx={{ mb: 0.5, ml: 0 }}>
                        What is your height?
                      </FormHelperText>
                      <TextField
                        id="outlined-basic"
                        label="Height"
                        variant="outlined"
                        fullWidth
                        type="number"
                        placeholder="Height in cm"
                        size="small"
                        name="first_name"
                        value={memberData.first_name}
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl fullWidth>
                      <FormHelperText sx={{ mb: 0.5, ml: 0 }}>
                        What is your weight?
                      </FormHelperText>
                      <TextField
                        id="outlined-basic"
                        label="Weight "
                        variant="outlined"
                        fullWidth
                        type="number"
                        placeholder="Weight in kg"
                        size="small"
                        value={memberData.last_name}
                        name="last_name"
                        onChange={(e) => handleChange(e)}
                        required={true}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl fullWidth>
                      <FormHelperText sx={{ mb: 0.5, ml: 0 }}>
                        What is your VDOT?
                      </FormHelperText>
                      <TextField
                        id="outlined-basic"
                        label="VDOT"
                        variant="outlined"
                        fullWidth
                        type="number"
                        size="small"
                        value={memberData.facebook_link}
                        name="facebook_link"
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl fullWidth>
                      <FormHelperText sx={{ mb: 0.5, ml: 0 }}>
                        What is your BP ?
                      </FormHelperText>
                      <TextField
                        id="outlined-basic"
                        label="BP"
                        variant="outlined"
                        fullWidth
                        type="number"
                        size="small"
                        value={memberData.linkedin_link}
                        name="linkedin_link"
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl fullWidth>
                      <FormHelperText sx={{ mb: 0.5, ml: 0 }}>
                        What is your Oxigen level?
                      </FormHelperText>
                      <TextField
                        id="outlined-basic"
                        label="Oxigen level"
                        variant="outlined"
                        fullWidth
                        type="number"
                        size="small"
                        value={memberData.youtube_link}
                        name="youtube_link"
                        onChange={(e) => handleChange(e)}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mt-4">
                    <FormControl fullWidth>
                      <FormHelperText sx={{ mb: 0.5, ml: 0 }}>
                        What is your Stress level?
                      </FormHelperText>
                    </FormControl>
                    <TextField
                      id="outlined-basic"
                      label="Stress level"
                      variant="outlined"
                      fullWidth
                      type="number"
                      placeholder="1 to 10"
                      size="small"
                      value={memberData.website_link}
                      name="website_link"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-2 ms-auto">
                <button className="small-contained-button mt-4 ">Update</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UserInformation;
