import {
  Autocomplete,
  Avatar,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Box,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Chip,
  Link,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

import { s3baseUrl } from "src/config/config";

import Label from "src/components/Label";

import ToshowExercise from "./component/modalToshowExercise";
import CongratulationAnimation from "./component/CongratulationAnimation";

import ConfirmationWithDescription from "src/components/ConfirmationWithDescription";
import AMRAPExercisesUI from "./AMRAPExercisesUI";
import EMOMExercisesUI from "./EMOMExercisesUI";
import SomeThingElseExercisesUI from "./SomeThingElseExercisesUI";
import { paceFromVdot } from "src/utils/constants";
import { programme_workout_exercise_history_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import { Icon } from "@iconify/react";
import ExerciseHistoryModel from "./component/ExerciseHistoryModel";
import ModalToShowAnExerciseInfo from "./component/ModalToShowAnExerciseInfo";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 20,
    },
  },
};
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "fixed",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const findPaceObjectByVdot = (vdotValue) => {
  return paceFromVdot.find((paceObject) => paceObject.vdot == vdotValue);
};
const WorkoutExercisesAnswerUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  original,
  isWorkoutCompleted,
  setIsWorkoutCompleted,
  memberRewardData,
  practiceType,
  handleSubmit,
}) => {
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [serchText, setSerchText] = useState("");
  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [openExerciseAlert, setOpenExerciseAlert] = useState(false);
  const [openSuperSetAlert, setOpenSuperSetAlert] = useState(false);
  const [isCompleteWork, setIsCompleteWork] = useState(false);
  const [alterIndex, setalterIndex] = useState();
  const [historyModalData, setHistoryModalData] = useState([]);
  const [navItems, setNavitems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isReaload, setIsReaload] = useState(false);
  const [exercise, setExercise] = useState([]);
  const [workoutTitle, setWorkoutTitle] = useState("Workout Name !");
  const [file, setImage] = React.useState({});
  const [previews, setPreviews] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [oldProfile, setOldProfile] = useState();
  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");
  const [openSingleExerciseInfo, setOpenSingleExerciseInfo] = useState(false);
  const [singleExerciseInfo, setSingleExerciseInfo] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [workoutDescription, setWorkoutDescription] = useState(
    "Workout description"
  );
  const [isHovered, setIsHovered] = useState("");
  const [isHoveredExercise, setIsHoveredExercise] = useState("");
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [congratulation, setCongratulation] = useState(false);
  const [historyModalOpenSuperset, setHistoryModalOpenSuperset] =
    useState(false);
  const handleUpload = (event) => {
    setImageStatus(true);
    // setImage(event.target.files[0]);
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 1000;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };
  function durationToSeconds(durationString) {
    // Regular expression to match a number and a time unit (sec, min, hour, etc.)
    const regex = /(\d+)\s+(sec|min|hour|hr|day|week|month|year)s?/i;

    // Use the regex to extract the number and unit from the input string

    const match = durationString && durationString.match(regex);

    if (match) {
      const value = parseInt(match[1], 10);
      const unit = match[2].toLowerCase();

      // Define conversion factors for different time units
      const unitToSeconds = {
        sec: 1,
        min: 60,
        hour: 3600,
        hr: 3600,
        day: 86400,
        week: 604800,
        month: 2592000,
        year: 31536000,
      };

      if (unitToSeconds.hasOwnProperty(unit)) {
        return value * unitToSeconds[unit];
      }
    }

    return 0; // Invalid duration format
  }
  const handleAdd = (i) => {
    let list = addArray;
    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          reps: "",
          rest: "",
        },
      ],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setModalChangeExe(true);
    setaddArray([...list]);
  };
  const handleAddSet = (mainindex, index) => {
    let list = addArray;
    const data = { ...list[mainindex]["set"][index] };
    list[mainindex]["set"].splice(index + 1, 0, data);

    setModalChangeExe(true);
    setaddArray([...list]);
  };
  const handleDeleteSet = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index, 1);

    setModalChangeExe(true);
    setaddArray([...list]);
  };

  const handleAddSupersetSets = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index + 1, 0, {
      time: "",
      weight: "",
      weightType: "kg",
      heightType: "cm",
      height: "",
      distance: "",
      distanceType: "m",
      reps: "",
      rest: "",
    });

    setModalChangeExe(true);
    setaddArray([...list]);
  };

  const addSuperSet2 = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
      const list = [...addArray];
      list[index]["superSet"] = array;

      setModalChangeExe(true);
      setaddArray(list);
      handleDelete(index + 1);
    } else {
      if (addArray[index + 1].set.length > addArray[index].set.length) {
        const setDifference =
          addArray[index + 1].set.length - addArray[index].set.length;
        addArray[index + 1].set.splice(0, setDifference);
      } else {
        const setDifference =
          addArray[index].set.length - addArray[index + 1].set.length;
        if (setDifference > 0) {
          addArray[index + 1].set.push(
            ...Array.from({ length: setDifference }, () => ({
              time: "",
              weight: "",
              weightType: "kg",
              heightType: "cm",
              height: "",
              distance: "",
              distanceType: "m",
              reps: "",
              rest: "",
            }))
          );
        }
      }

      array.push(addArray[index + 1]);
      const list = [...addArray];
      list[index]["superSet"] = array;

      setModalChangeExe(true);
      setaddArray(list);
      handleDelete(index + 1);
    }
  };
  const handleDeleteExer = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setModalChangeExe(true);
    setaddArray(delValue);
  };
  const removeSuperSet = (mainIndex, index) => {
    //  const { name, value } = e.target;
    const list = [...addArray];
    let firstPart = list[mainIndex]["superSet"].slice(0, index + 1);
    let secondPart = list[mainIndex]["superSet"].slice(index + 1);
    let firstObject = { ...firstPart[0] };
    if (index > 0) {
      firstObject.superSet = firstPart;
      firstObject.superset_sets = list[mainIndex].superset_sets;
      // superset_sets;
    }

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) {
      secondObject.superSet = secondPart;
      secondObject.superset_sets = list[mainIndex].superset_sets;
    }

    let list1 = addArray;
    list1.splice(mainIndex + 1, 0, secondObject);
    list1.splice(mainIndex + 1, 0, firstObject);

    setModalChangeExe(true);
    setaddArray([...list1]);
    handleDeleteExer(mainIndex);
  };

  const handleAgreeSuperSet = (mainindex) => {
    setSuperAlterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superset_sets
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superset_sets
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addInSuperSet(mainindex);
    } else setOpenSuperSetAlter(true);
  };
  const handleAgreeExercise = (mainindex) => {
    setalterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superSet.length
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superSet.length
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addSuperSet(mainindex);
    } else {
      setOpenExerciseAlter(true);
    }
  };
  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[index + 1]?.superSet &&
      addArray[index + 1].superSet.length > 0
    ) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[index].set.length,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenExerciseAlter(false);
  };
  const addInSuperSet = (index) => {
    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }
    const list = [...addArray];
    list[index]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);
    let e = {
      target: {
        name: "superset_sets",
        value: list[index].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenSuperSetAlter(false);
  };
  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleAgreeInSuperSet = () => {
    const list = [...addArray];
    list[detailsData]["superSet"][exerIndex]["is_completed"] = false;
    setIsWorkoutCompleted(false);
    setModalChangeExe(true);
    setOpenSuperSetAlert(false);
    setaddArray(list);
  };
  const handleAgreeInExerciseSet = () => {
    const list = [...addArray];
    list[exerIndex]["is_completed"] = false;
    setIsWorkoutCompleted(false);
    setModalChangeExe(true);
    setOpenExerciseAlert(false);
    setaddArray(list);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setModalChangeExe(true);
    setaddArray(delValue);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const get_accessment_setting = async () => {
    setIsReaload(true);
    const result = await _get_attitude_accessment_setting();
    if (result.code == 200) {
      if (result.assessment_setting[level]?.length > 0) {
        const config_array = result.assessment_setting[level]?.map(
          (config, i) => {
            return {
              ...config,
              id: "coin" + i,
            };
          }
        );

        setModalChangeExe(true);
        setaddArray(config_array);
      }
      setIsReaload(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsReaload(false);
    }
  };
  const handleChangeComplete = (e, index) => {
    const { checked } = e.target;
    // if (original[index]["is_completed"]) {
    //   return;
    // }
    const list = [...addArray];
    if (!checked && isWorkoutCompleted) {
      setExerIndex(index);
      setOpenExerciseAlert(true);
      return;
    }
    list[index]["is_completed"] = checked;

    setModalChangeExe(true);
    setaddArray(list);

    if (checked) setCongratulation(true);
  };
  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    setaddArray(list);
  };
  const handleChangeCompleteSet = (e, mainindex, exerIndex) => {
    const { checked } = e.target;
    // if (original[mainindex]["superSet"][exerIndex]["is_completed"]) {
    //   return;
    // }
    const list = [...addArray];

    if (!checked && isWorkoutCompleted) {
      setDetailsData(mainindex);
      setExerIndex(exerIndex);
      setOpenSuperSetAlert(true);
      return;
    }
    list[mainindex]["superSet"][exerIndex]["is_completed"] = checked;
    setIsCompleteWork(true);
    setModalChangeExe(true);
    setaddArray(list);

    if (checked) setCongratulation(true);
  };
  const handleArrowSuperSet = (value, mainindex, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["superSet"][exerIndex]["open"] = value;

    setaddArray(list);
  };
  const handleChangeOther = (e, index) => {
    const list = [...addArray];

    list[index]["exercise"] = e;
    list[index]["description"] = e?.instructions;

    setModalChangeExe(true);
    setaddArray(list);
  };
  function handleOnDragEnd(result) {
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  }
  const handleChangeNavItem = (event, index) => {
    let list = [...addArray];
    const {
      target: { value },
    } = event;
    list[index]["nav_items"] =
      typeof value === "string" ? value.split(",") : value;

    setModalChangeExe(true);
    setaddArray(list);
    setNavitems(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index]["set"][index1][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const getVdotPace = (index, index1) => {
    const list = [...addArray];
    const vodt = list[index]["set"][index1]["vdot"];
    const distanceType = list[index]["set"][index1]["race_type"];
    const paceType = list[index]["set"][index1]["pace_type"];

    // Example: Find object with vdot value of 33
    const vdot33Object = findPaceObjectByVdot(vodt);
    if (distanceType != "el") {
      list[index]["set"][index1]["pace"] = vdot33Object[paceType];
    } else {
      list[index]["set"][index1]["pace"] = vdot33Object[paceType + "_low"];
      list[index]["set"][index1]["paceUp"] = vdot33Object[paceType + "_up"];
    }

    setaddArray(list);
    // setModalChangeExe(true);
  };
  const handleHistoryOpen = async (value, index) => {
    setDetailsData(index);
    handleHistory(value?.exercise, index);

    // setHistoryModalData(value.history);
  };
  const handleHistory = async (value, index) => {
    setDetailsData(index);
    let postData = {
      exercise: value?._id,
    };
    setHistoryModalData([]);
    setIsLoading(true);
    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[index]["history"];
          setaddArray(list);

          return;
        }

        let givenExercise = result.exercise_history.map((item) => {
          return {
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                mhr: item1?.mhr,
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                height: item1?.height,
                heightType: item1?.height_unit,
                reps: item1?.reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low,
                paceUp: item1?.pace_up,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "KM Pace", key: "c_km" },
                        { title: "MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });

        setHistoryModalData(givenExercise);

        // setHistoryModalOpen(true);
        const list = [...addArray];
        list[index]["history"] = givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setHistoryModalOpen(true);
    }
  };
  const handleHistorySupersetOpen = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    // setHistoryModalData(value.history);
    handleHistorySuperset(value.exercise, mainIndex, exerInde);
  };
  const handleHistorySuperset = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    let postData = {
      exercise: value?._id,
    };
    setHistoryModalData([]);
    setIsLoading(true);
    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[mainIndex]["superSet"][exerInde]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                mhr: item1?.mhr,
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                height: item1?.height,
                heightType: item1?.height_unit,
                reps: item1?.reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low,
                paceUp: item1?.pace_up,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "KM Pace", key: "c_km" },
                        { title: "MILE Pace", key: "c_mi" },
                      ],
                mhr: item1?.mhr,
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });
        console.log(givenExercise, "kjdshakfjsdfhaj");
        setHistoryModalData(givenExercise);
        // setIsLoading(false);
        // setHistoryModalOpenSuperset(true);
        const list = [...addArray];
        list[mainIndex]["superSet"][exerInde]["history"] = givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setHistoryModalOpenSuperset(true);
    }
  };
  const handleChangeVdotPaceType = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;
    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeVdot = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeVdotDistance = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index].set[index1][name] = value;

    let optionsTypeSArray = [];

    switch (value) {
      case "r":
        optionsTypeSArray = [
          { title: "200 Meters Pace", key: "r_200_m" },
          { title: "300 Meters Pace", key: "r_300_m" },
          { title: "400 Meters Pace", key: "r_400_m" },
          { title: "600 Meters Pace", key: "r_600_m" },
          { title: "800 Meters Pace", key: "r_800_m" },
        ];
        break;

      case "c":
        optionsTypeSArray = [
          { title: "KM Pace", key: "c_km" },
          { title: "MILE Pace", key: "c_mi" },
        ];
        break;

      case "t":
        optionsTypeSArray = [
          { title: "400 Meters Pace", key: "t_400_m" },
          { title: "KM Pace", key: "t_km" },
          { title: "MILE Pace", key: "t_mi" },
        ];
        break;

      case "i":
        optionsTypeSArray = [
          { title: "400 Meters Pace", key: "i_400_m" },
          { title: "KM Pace", key: "i_km" },
          { title: "1200 Meters Pace", key: "i_1200_m" },
          { title: "MILE Pace", key: "i_mi" },
        ];
        break;

      default:
        optionsTypeSArray = [
          { title: "KM Pace", key: "e_km" },
          { title: "MILE Pace", key: "e_mi" },
        ];
    }
    list[index].set[index1]["optionsType"] = optionsTypeSArray;
    list[index].set[index1]["pace_type"] = optionsTypeSArray[0].key;

    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeSuperSetDes = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index]["superSet"][index1][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeTempo = (e, index, index1) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];

      list[index]["set"][index1][name] = value;

      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleChangeSuperSetTempo = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];
      list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] = value;
      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleChangeSuperSet = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleAddSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) + 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;
      const data = { ...superSetItem.set[currentSetLength - 1] };
      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;
        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push(data);
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleMinusSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) - 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeSuperSetCount = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];

    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  };
  const handleExerciseList = (value) => {
    setIsExerciseForSuperSet(false);
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSupersetExerciseList = (mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setIsExerciseForSuperSet(true);
  };
  const handleClickOpen = async (exercise) => {
    setSingleExerciseInfo(exercise);
    setOpenSingleExerciseInfo(true);
  };
  const handleSelectExercise = (value) => {
    if (addArray[detailsData]["exercise"]._id == value._id) {
      return;
    }

    const list = [...addArray];
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;

    if (original[detailsData]["exercise"]._id == value._id) {
      list[detailsData]["is_modified"] = original[detailsData]["is_modified"];
    } else list[detailsData]["is_modified"] = true;

    list[detailsData]["open"] = true;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleSelectSupersetExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["superSet"][exerIndex]["exercise"] = value;
    list[detailsData]["superSet"][exerIndex]["description"] =
      value?.instructions;

    if (
      original[detailsData] &&
      original[detailsData]["superSet"] &&
      original[detailsData]["superSet"][exerIndex]["exercise"] &&
      original[detailsData]["superSet"][exerIndex]["exercise"]._id == value._id
    ) {
      list[detailsData]["superSet"][exerIndex]["is_modified"] =
        original[detailsData]["superSet"][exerIndex]["is_modified"];
    } else list[detailsData]["superSet"][exerIndex]["is_modified"] = true;
    list[detailsData]["superSet"][exerIndex]["open"] = true;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  };
  const getPaceTextinPaces = (type) => {
    let result = "";
    if (type == "m") {
      result = "Time Cap";
    } else if (type == "mi") {
      result = "Pace Min/Mile";
    } else if (type == "km") {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getKmhrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = (value / time) * 3.6;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6 * 1.609;
    } else if (type == "km") {
      result = ((1 * 1000) / time) * 3.6;
    }
    return result.toFixed(2);
  };
  const getMihrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = result = ((value / time) * 3.6) / 1.609;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6;
    } else if (type == "km") {
      result = (((1 * 1000) / time) * 3.6) / 1.609;
    }
    return result.toFixed(2);
  };
  const getPaceText = (type) => {
    let result = "";
    if (type?.includes("_200_")) {
      result = "Time Cap";
    } else if (type?.includes("_300_")) {
      result = "Time Cap";
    } else if (type?.includes("_400_")) {
      result = "Time Cap";
    } else if (type?.includes("_600_")) {
      result = "Time Cap";
    } else if (type?.includes("_800_")) {
      result = "Time Cap";
    } else if (type?.includes("_1200_")) {
      result = "Time Cap";
    } else if (type?.includes("mi")) {
      result = "Pace Min/Mile";
    } else if (type?.includes("km")) {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getKmhr = (value, type) => {
    let result = 0;
    if (type?.includes("_200_")) {
      result = (200 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_300_")) {
      result = (300 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_400_")) {
      result = (400 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_600_")) {
      result = (600 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_800_")) {
      result = (800 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_1200_")) {
      result = (1200 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("mi")) {
      result = (3600 / parseInt(value)) * 1.609;
    } else if (type?.includes("km")) {
      result = 3600 / parseInt(value);
    }
    return result.toFixed(2);
  };
  const getMihr = (value, type) => {
    let result = 0;
    if (type?.includes("_200_")) {
      result = (200 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_300_")) {
      result = (300 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_400_")) {
      result = (400 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_600_")) {
      result = (600 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_800_")) {
      result = (800 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_1200_")) {
      result = (1200 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("mi")) {
      result = 3600 / parseInt(value);
    } else if (type?.includes("km")) {
      result = 3600 / parseInt(value) / 1.609;
    }
    return result.toFixed(2);
  };
  const handleMinutesChange = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];

    const minutes = parseInt(e.target.value) || 0;
    const seconds = list[index]["set"][index1][name] % 60;
    updateIntervalTime(minutes, seconds, index, index1, name);
  };

  const handleSecondsChange = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];

    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(list[index]["set"][index1][name] / 60);
    updateIntervalTime(minutes, seconds, index, index1, name);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (minutes, seconds, index, index1, name) => {
    const totalSeconds = minutes * 60 + seconds;
    const list = [...addArray];
    list[index]["set"][index1][name] = totalSeconds;
    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleMinutesChangeSets = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    const minutes = parseInt(e.target.value) || 0;
    const seconds =
      list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] % 60;
    updateIntervalTimeSets(
      minutes,
      seconds,
      mainindex,
      exerIndex,
      setIndex,
      name
    );
  };

  const handleSecondsChangeSets = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;
    const list = [...addArray];

    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(
      list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] / 60
    );
    updateIntervalTimeSets(
      minutes,
      seconds,
      mainindex,
      exerIndex,
      setIndex,
      name
    );
  };

  // Function to update intervalTime state
  const updateIntervalTimeSets = (
    minutes,
    seconds,
    mainindex,
    exerIndex,
    setIndex,
    name
  ) => {
    const totalSeconds = minutes * 60 + seconds;
    const list = [...addArray];
    list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] =
      totalSeconds;
    setaddArray(list);
    setModalChangeExe(true);
  };
  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (congratulation) {
        setCongratulation(false);
        handleSubmit(true);
      }
      // Update the state after 3 seconds
    }, 2500);

    return () => clearTimeout(timeout); // Clean up the timeout to avoid memory leaks
  }, [congratulation]);
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="w-100">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      {congratulation && <CongratulationAnimation />}
      {/* <CongratulationAnimation /> */}
      <ConfirmationWithDescription
        open={openSuperSetAlert}
        setOpen={setOpenSuperSetAlert}
        title={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          // ? "Opps!" :
          "Are you sure you want to taaaake this action?"
        }
        descriotion={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //  ? "You are not able to uncomplete because you have insufficient points/coins." :
          `If you uncheck, your points or coins allocated for this ${practiceType} will be deducted.`
        }
        handleAgree={handleAgreeInSuperSet}
        isJustForOk={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? true:
          false
        }
      />
      <ConfirmationWithDescription
        open={openExerciseAlert}
        setOpen={setOpenExerciseAlert}
        title={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          // ? "Opps!" :
          //general program workout mark as complete
          "Are you sure you want to take this action?"
        }
        descriotion={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          // ? "You are not able to uncomplete because you have insufficient points/coins." :
          `If you uncheck, your points or coins allocated for this ${practiceType} will be deducted.`
        }
        handleAgree={handleAgreeInExerciseSet}
        isJustForOk={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? true:
          false
        }
      />
      <ModalToShowAnExerciseInfo
        open={openSingleExerciseInfo}
        setOpen={setOpenSingleExerciseInfo}
        Id={singleExerciseInfo}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={handleSelectSupersetExercise}
      />
      <ExerciseHistoryModel
        open={historyModalOpen}
        setOpen={setHistoryModalOpen}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={() => {}}
      />
      <ExerciseHistoryModel
        open={historyModalOpenSuperset}
        setOpen={setHistoryModalOpenSuperset}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={() => {}}
      />
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            {addArray?.map((data, mainindex) => {
              return (
                <>
                  {data?.type == "something_else" ? (
                    <SomeThingElseExercisesUI
                      addArray={addArray}
                      setaddArray={setaddArray}
                      setModalChangeExe={setModalChangeExe}
                      mainindex={mainindex}
                      isHistory={false}
                      memberId={""}
                      isWorkoutCompleted={isWorkoutCompleted}
                      setIsWorkoutCompleted={setIsWorkoutCompleted}
                      memberRewardData={memberRewardData}
                      practiceType={practiceType}
                      handleSubmit={handleSubmit}
                    />
                  ) : data?.type == "amrap" ? (
                    <AMRAPExercisesUI
                      addArray={addArray}
                      setaddArray={setaddArray}
                      setModalChangeExe={setModalChangeExe}
                      mainindex={mainindex}
                      isHistory={false}
                      memberId={""}
                      isWorkoutCompleted={isWorkoutCompleted}
                      setIsWorkoutCompleted={setIsWorkoutCompleted}
                      memberRewardData={memberRewardData}
                      practiceType={practiceType}
                      handleSubmit={handleSubmit}
                    />
                  ) : data?.type == "emom" ? (
                    <EMOMExercisesUI
                      addArray={addArray}
                      setaddArray={setaddArray}
                      setModalChangeExe={setModalChangeExe}
                      mainindex={mainindex}
                      isHistory={false}
                      memberId={""}
                      isWorkoutCompleted={isWorkoutCompleted}
                      setIsWorkoutCompleted={setIsWorkoutCompleted}
                      memberRewardData={memberRewardData}
                      practiceType={practiceType}
                      handleSubmit={handleSubmit}
                    />
                  ) : data?.superSet && data?.superSet.length > 0 ? (
                    <>
                      <div
                        style={{ position: "relative" }}
                        className="workout-Superset-card my-2">
                        {/* <SuperSets length={data?.superset_sets} data={data} /> */}
                        <Box>
                          <div className="d-flex align-items-start justify-content-between ">
                            <div className="d-flex align-items-center w-100  mb-2">
                              <div>Superset of</div>
                              <div className="ms-2 cross-icon ">
                                <RemoveCircleIcon
                                  onClick={() => handleMinusSetCount(mainindex)}
                                  className="diary-icon-plus-minus"
                                />
                              </div>

                              <TextField
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                placeholder="Sets"
                                type="number"
                                value={data.superset_sets}
                                name="superset_sets"
                                disabled
                                onChange={(e) =>
                                  handleChangeSuperSetCount(e, mainindex)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                //   label="Set"
                                InputProps={{
                                  className: "textfiels-input-value",
                                  style: { fontSize: "13px", padding: "0" },
                                }}
                                sx={{
                                  mx: 1,
                                  borderRadius: "5px",
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                  fontSize: "13px",
                                  "& input": {
                                    padding: "3px 5px",
                                    textAlign: "center",
                                  },
                                  width: "30px",
                                }}
                              />

                              <div className=" cross-icon me-2">
                                <AddCircleIcon
                                  onClick={() => handleAddSetCount(mainindex)}
                                  className="diary-icon-plus-minus"
                                />
                              </div>
                              <div>sets</div>
                            </div>
                          </div>

                          <div className=" mb-2 ">
                            {data?.superSet?.map((exerData, exerIndex) => (
                              <div
                                className="d-flex  workout-set-card"
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  overflow: "hidden",
                                }}>
                                <Box
                                  sx={{
                                    // position: "relative",
                                    width: "100%",
                                  }}>
                                  <div
                                    className="row  "
                                    onMouseEnter={() => handleMouseLeave()}>
                                    <div
                                      className={`col-sm-12 ${"col-md-7"} col-lg-6 d-flex align-items-center p-0 mt-2 mt-md-0 mt-lg-0`}>
                                      {exerData?.is_modified && (
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            justifyContent: "center",
                                            zIndex: "1",
                                            left: "-18px",
                                            transform: "rotate(-53deg)",
                                          }}>
                                          <Chip
                                            label={"Modified"}
                                            variant="contained"
                                            color={"error"}
                                            sx={{
                                              fontSize: "10px",
                                              height: "auto",
                                              width: "auto",
                                            }}
                                          />
                                        </span>
                                      )}
                                      <div className="exercise-label-box">
                                        <span className="outer-label">
                                          Exercise
                                        </span>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div
                                            className="exercise-label w-100"
                                            onClick={() =>
                                              handleSupersetExerciseList(
                                                mainindex,
                                                exerIndex
                                              )
                                            }>
                                            <div className="d-flex align-items-center">
                                              {exerData?.exercise?.image
                                                ?.thumbnail_3 && (
                                                <Avatar
                                                  sx={{ borderRadius: "5px" }}
                                                  alt="User Image"
                                                  src={
                                                    s3baseUrl +
                                                    exerData?.exercise?.image
                                                      ?.thumbnail_3
                                                  }
                                                />
                                              )}
                                              <div className="label-text ms-1">
                                                {exerData?.exercise?.title
                                                  ? exerData?.exercise?.title
                                                  : ""}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="exercise-action">
                                            <div style={{ fontSize: "16px" }}>
                                              {exerData?.exercise ? (
                                                <div>
                                                  <InfoIcon
                                                    onClick={() =>
                                                      handleClickOpen(
                                                        exerData?.exercise?._id
                                                      )
                                                    }
                                                    className="d-block"
                                                    fontSize="14px"
                                                    sx={{
                                                      opacity: 0.5,
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <EditIcon
                                                    onClick={() =>
                                                      handleSupersetExerciseList(
                                                        mainindex,
                                                        exerIndex
                                                      )
                                                    }
                                                    fontSize="12px"
                                                    sx={{
                                                      opacity: 0.5,
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </div>
                                              ) : (
                                                <AddIcon
                                                  onClick={() =>
                                                    handleSupersetExerciseList(
                                                      mainindex,
                                                      exerIndex
                                                    )
                                                  }
                                                  sx={{
                                                    opacity: 0.5,
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <Button
                                        sx={{ textTransform: "inherit" }}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                        onClick={() => {
                                          handleArrowSuperSet(
                                            !exerData?.open ? true : false,
                                            mainindex,
                                            exerIndex
                                          );
                                        }}>
                                        {!exerData?.open ? (
                                          <KeyboardArrowDownIcon
                                            sx={{
                                              cursor: "pointer",
                                              // opacity: 0.5,
                                              fontSize: "20px",
                                            }}
                                          />
                                        ) : (
                                          <KeyboardArrowUpIcon
                                            sx={{
                                              cursor: "pointer",
                                              // opacity: 0.5,
                                              fontSize: "20px",
                                            }}
                                          />
                                        )}

                                        <Typography
                                          variant="body2"
                                          sx={{
                                            // opacity: 0.6,
                                            whiteSpace: "nowrap",
                                          }}>
                                          {` Sets ${exerData?.set?.length}`}
                                        </Typography>
                                      </Button>
                                    </div>

                                    <div
                                      className={`col-sm-12 ${"col-md-5"} col-lg-6 p-0 mt-2 mt-md-0 mt-lg-0 d-flex align-items-center justify-content-start `}>
                                      <div className="d-flex align-items-center mx-1">
                                        {exerData?.exercise && (
                                          <Button
                                            sx={{ textTransform: "inherit" }}
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                            onClick={() => {
                                              handleHistorySupersetOpen(
                                                exerData,
                                                mainindex,
                                                exerIndex
                                              );
                                            }}>
                                            <Icon
                                              icon="fa-solid:history"
                                              fontSize="10px"
                                            />
                                            <Typography
                                              variant="body2"
                                              sx={{ ml: 0.5 }}>
                                              {`History`}
                                            </Typography>
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {exerData?.open &&
                                    exerData?.set?.map((item, setIndex) => (
                                      <div className="d-flex align-items-center my-3 workout-card-inset">
                                        <div className="d-flex align-items-center w-100">
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              padding: "25px 4px",
                                              color: "inherit",
                                              backgroundColor: "#edeff1",
                                              height: "100%",
                                              width: "15px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              borderRadius: "7px",
                                            }}>
                                            {setIndex + 1}
                                          </Typography>
                                          <CloseIcon sx={{ opacity: 0.3 }} />
                                          <div className="row w-100">
                                            {exerData.parameters.includes(
                                              "Pace"
                                            ) && (
                                              <>
                                                <div
                                                  className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}>
                                                  <FormControl
                                                    readOnly={true}
                                                    fullWidth>
                                                    <InputLabel
                                                      className="set-Type-lable"
                                                      sx={{
                                                        fontSize: "14px",
                                                      }}
                                                      id="demo-simple-select-label">
                                                      Type
                                                    </InputLabel>

                                                    <Select
                                                      className="set-Type-value"
                                                      id="select-option"
                                                      variant="outlined"
                                                      size="small"
                                                      name="race_type"
                                                      value={item?.race_type}
                                                      label="Type"
                                                      readOnly={true}
                                                      inputProps={{
                                                        style: {
                                                          padding: 0,
                                                        },
                                                        readOnly: true,
                                                      }}
                                                      onChange={(e) => {
                                                        handleChangeSuperSet(
                                                          e,
                                                          mainindex,
                                                          exerIndex,
                                                          setIndex
                                                        );
                                                      }}
                                                      sx={{
                                                        width: "100%",
                                                        fontSize: "13px",
                                                        "& .MuiOutlinedInput-input":
                                                          {
                                                            paddingLeft: 1,
                                                            paddingRight:
                                                              "16px !important",
                                                          },
                                                        "& input": {
                                                          textAlign: "center",
                                                          padding: "0px",
                                                        },
                                                      }}>
                                                      <MenuItem value="el">
                                                        Easy/Long
                                                      </MenuItem>
                                                      <MenuItem value="c">
                                                        Compromised
                                                      </MenuItem>

                                                      <MenuItem value="i">
                                                        Interval
                                                      </MenuItem>
                                                      <MenuItem value="r">
                                                        Repitition
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </div>
                                                <div
                                                  className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}>
                                                  <TextField
                                                    sx={{
                                                      borderRadius: "5px",
                                                      paddingRight: 0,
                                                      width: "100%",
                                                    }}
                                                    size="small"
                                                    id="custom-input"
                                                    variant="outlined"
                                                    placeholder={"Distance"}
                                                    name="distance"
                                                    label="Distance"
                                                    value={item?.distance}
                                                    onChange={(e) => {
                                                      handleChangeSuperSet(
                                                        e,
                                                        mainindex,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    readOnly
                                                    type="number"
                                                    InputLabelProps={{
                                                      shrink: true,
                                                      className:
                                                        "textfiels-input-label",
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="distanceType"
                                                            value={
                                                              item?.distanceType
                                                            }
                                                            label="Weight Type"
                                                            onChange={(e) => {
                                                              handleChangeSuperSet(
                                                                e,
                                                                mainindex,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            readOnly={true}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                            }}>
                                                            <MenuItem value="m">
                                                              Meter
                                                            </MenuItem>
                                                            <MenuItem value="km">
                                                              KM
                                                            </MenuItem>
                                                            <MenuItem value="mi">
                                                              Mile
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  className={`col-12 col-md-2 col-lg-2 pe-0 py-2`}>
                                                  <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    label={getPaceTextinPaces(
                                                      item?.distanceType
                                                    )}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingLeft: "0px",
                                                        paddingRight: "0px",

                                                        justifyContent:
                                                          "center",
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position="end"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}>
                                                          <TextField
                                                            id="outlined-basic-minutes"
                                                            type="number"
                                                            size="small"
                                                            placeholder="Min"
                                                            name="pace"
                                                            value={formatWithLeadingZeros(
                                                              Math.floor(
                                                                item?.pace / 60
                                                              )
                                                            )}
                                                            onChange={(e) =>
                                                              handleMinutesChangeSets(
                                                                e,
                                                                mainindex,
                                                                exerIndex,
                                                                setIndex
                                                              )
                                                            }
                                                            InputProps={{
                                                              className:
                                                                "textfiels-input-value",
                                                              style: {
                                                                fontSize:
                                                                  "14px",
                                                              },
                                                              readOnly: true,
                                                            }}
                                                            sx={{
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& input": {
                                                                textAlign:
                                                                  "center",
                                                                padding: "0px",
                                                              },
                                                              width: "100%",
                                                            }}
                                                          />
                                                          <div className="fs-6 fw-semibold ms-1 ">
                                                            :
                                                          </div>

                                                          <TextField
                                                            id="outlined-basic-seconds"
                                                            type="number"
                                                            size="small"
                                                            placeholder="Sec"
                                                            name="pace"
                                                            value={formatWithLeadingZeros(
                                                              item?.pace % 60
                                                            )}
                                                            onChange={(e) =>
                                                              handleSecondsChangeSets(
                                                                e,
                                                                mainindex,
                                                                exerIndex,
                                                                setIndex
                                                              )
                                                            }
                                                            InputProps={{
                                                              className:
                                                                "textfiels-input-value",
                                                              style: {
                                                                fontSize:
                                                                  "14px",
                                                              },
                                                              readOnly: true,
                                                            }}
                                                            sx={{
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& input": {
                                                                textAlign:
                                                                  "center",
                                                                padding: "0px",
                                                              },
                                                              width: "100%",
                                                            }}
                                                          />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                        {
                                                          width:
                                                            "0px !important",
                                                          padding:
                                                            "9px 0px !important",
                                                        },
                                                      "& .css-1nuss9t": {
                                                        width: "0px !important",
                                                        padding:
                                                          "9px 0px !important",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    bottom: "4px",
                                                    left: "5px",
                                                  }}>
                                                  <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                      opacity: 0.6,
                                                      fontWeight: "600",
                                                      fontSize: "12px",
                                                    }}>{` @ Speed ${getKmhrForPace(
                                                    item?.distance,
                                                    item?.pace,
                                                    item?.distanceType
                                                  )} km/hr or ${getMihrForPace(
                                                    item?.distance,
                                                    item?.pace,
                                                    item?.distanceType
                                                  )} mi/hr`}</Typography>
                                                </div>
                                              </>
                                            )}
                                            {(exerData.parameters.includes(
                                              "Duration/Pace"
                                            ) ||
                                              exerData.parameters.includes(
                                                "Time"
                                              )) && (
                                              <div
                                                className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}>
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.time}
                                                  name="time"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label={
                                                    exerData.parameters.includes(
                                                      "Duration/Pace"
                                                    )
                                                      ? "Duration/Pace"
                                                      : "Time"
                                                  }
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="timeType"
                                                          value={item?.timeType}
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}

                                            {exerData.parameters.includes(
                                              "Reps"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Reps"
                                                  type="number"
                                                  label="Reps"
                                                  value={item?.reps}
                                                  name="reps"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Height"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.height}
                                                  name="height"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Height"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="heightType"
                                                          value={
                                                            item?.heightType
                                                          }
                                                          label="Height Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="cm">
                                                            Cm
                                                          </MenuItem>
                                                          <MenuItem value="ft">
                                                            Feet
                                                          </MenuItem>
                                                          <MenuItem value="inch">
                                                            Inch
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Weight"
                                            ) && (
                                              <div className="col-12 col-md-4 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    paddingRight: 0,
                                                    width: "100%",
                                                  }}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder="Weight"
                                                  name="weight"
                                                  label="Weight"
                                                  value={item.weight}
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="weightType"
                                                          value={
                                                            item?.weightType
                                                          }
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="1rm">
                                                            % 1RM
                                                          </MenuItem>
                                                          <MenuItem value="body_weight">
                                                            % BW
                                                          </MenuItem>
                                                          <MenuItem value="kg">
                                                            KG
                                                          </MenuItem>
                                                          <MenuItem value="lb">
                                                            Pound
                                                          </MenuItem>
                                                          <MenuItem value="poods">
                                                            Poods
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Distance/Calories"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    paddingRight: 0,
                                                    width: "100%",
                                                  }}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder={
                                                    item?.distanceType ==
                                                    "calories"
                                                      ? "Calories"
                                                      : "Distance"
                                                  }
                                                  name="distance"
                                                  label="Distance/Calories"
                                                  value={item?.distance}
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="distanceType"
                                                          value={
                                                            item?.distanceType
                                                          }
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="m">
                                                            Meter
                                                          </MenuItem>
                                                          <MenuItem value="km">
                                                            KM
                                                          </MenuItem>
                                                          <MenuItem value="mi">
                                                            Mile
                                                          </MenuItem>
                                                          <MenuItem value="calories">
                                                            Calories
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "%MHR"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="%MHR"
                                                  type="number"
                                                  label="%MHR"
                                                  value={item?.mhr}
                                                  name="mhr"
                                                  onChange={(e) => {
                                                    handleChangeSuperSetTempo(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Tempo"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Tempo"
                                                  type="number"
                                                  label="Tempo"
                                                  value={item?.tempo}
                                                  name="tempo"
                                                  onChange={(e) => {
                                                    handleChangeSuperSetTempo(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",

                                                    fontSize: "13px",
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Rest"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.rest}
                                                  name="rest"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Rest"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="restType"
                                                          value={item?.restType}
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}>
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {exerData?.open && (
                                    <>
                                      <TextField
                                        id="outlined-basic"
                                        size="small"
                                        variant="outlined"
                                        placeholder="Instructions"
                                        label="Instructions"
                                        type="number"
                                        value={exerData.description}
                                        name="description"
                                        multiline
                                        rows={exerData.description ? null : 2}
                                        // onChange={(e) =>
                                        //   handleChangeSuperSetDes(
                                        //     e,
                                        //     mainindex,
                                        //     exerIndex
                                        //   )
                                        // }
                                        InputLabelProps={{
                                          className: "textfiels-input-label",
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        InputProps={{
                                          className: "textfiels-input-value",
                                          style: { fontSize: "14px" },
                                          readOnly: true,
                                        }}
                                        sx={{
                                          borderRadius: "5px",
                                          // "& legend": { display: "none" },
                                          // "& fieldset": { top: 0 },

                                          mt: 2,
                                          width: "100%",
                                        }}
                                      />
                                      <div className="d-flex justify-content-end mt-3">
                                        {" "}
                                        <FormGroup>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                sx={{ p: "3px" }}
                                                checked={exerData.is_completed}
                                                onChange={(e) =>
                                                  handleChangeCompleteSet(
                                                    e,
                                                    mainindex,
                                                    exerIndex
                                                  )
                                                }
                                              />
                                            }
                                            label="Mark as Complete"
                                          />
                                        </FormGroup>
                                      </div>
                                    </>
                                  )}
                                  {exerData?.open && (
                                    <div className="row mt-3 d-flex justify-content-center">
                                      <div className="col-md-2 col-lg-1 col-3">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();

                                            handleArrowSuperSet(
                                              false,
                                              mainindex,
                                              exerIndex
                                            );
                                          }}
                                          className="w-100 small-contained-set-button">
                                          <KeyboardArrowUpIcon
                                            sx={{
                                              cursor: "pointer",
                                              opacity: 0.5,
                                              fontSize: "20px",
                                            }}
                                            onClick={() => {
                                              handleArrowSuperSet(
                                                false,
                                                mainindex,
                                                exerIndex
                                              );
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {/* <span
                                    className="cross-icon"
                                    style={{
                                      position: "absolute",
                                      bottom: "-30px",

                                      width: "100%",
                                      justifyContent: "center",
                                      zIndex: 1,
                       
                                    }}
                                  >
                                    {data?.superSet.length > 1 &&
                                    exerIndex < data?.superSet.length - 1 ? (
                                      <div className="">
                                        <SyncDisabledIcon
                                          onClick={() =>
                                            removeSuperSet(mainindex, exerIndex)
                                          }
                                          className="sync-icon-remove"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </span> */}
                                </Box>
                              </div>
                            ))}
                          </div>
                        </Box>
                        {/* <span
                          className="cross-icon"
                          style={{
                            position: "absolute",
                            bottom: "-17px",
                            width: "100%",
                            justifyContent: "center",
                            zIndex: 1,
                       
                          }}
                        >
                          {addArray.length > 1 &&
                          mainindex < addArray.length - 1 ? (
                            <div className="">
                              <SyncIcon
                                onClick={() => handleAgreeSuperSet(mainindex)}
                                className="sync-icon-add"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </span> */}
                      </div>
                    </>
                  ) : (
                    <div
                      // direction="row"

                      className="d-flex my-2 workout-set-card w-100"
                      style={{
                        position: "relative",
                        width: "100%",
                        overflow: "hidden",
                      }}
                      onMouseEnter={() => handleMouseLeave()}>
                      <Box
                        sx={{
                          // position: "relative",
                          width: "100%",
                          // overflow: "hidden",
                        }}
                        onMouseEnter={() => handleMouseLeave()}>
                        <div
                          className="row  "
                          onMouseEnter={() => handleMouseLeave()}>
                          <div
                            className={`col-sm-12 ${"col-md-7"} col-lg-6 d-flex align-items-center p-0 mt-2 mt-md-0 mt-lg-0`}>
                            {data?.is_modified && (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  justifyContent: "center",
                                  zIndex: "1",
                                  left: "-18px",
                                  transform: "rotate(-53deg)",
                                }}>
                                <Chip
                                  label={"Modified"}
                                  variant="contained"
                                  color={"error"}
                                  sx={{
                                    fontSize: "10px",
                                    height: "auto",
                                    width: "auto",
                                  }}
                                />
                              </span>
                            )}
                            <div className="exercise-label-box">
                              <span className="outer-label">Exercise</span>
                              <div className="d-flex align-items-center justify-content-between">
                                <div
                                  className="exercise-label w-100"
                                  onClick={() => {
                                    data.parameters.includes("Vdot")
                                      ? ""
                                      : handleExerciseList(mainindex);
                                  }}>
                                  <div className="d-flex align-items-center">
                                    {data?.exercise?.image?.thumbnail_3 && (
                                      <Avatar
                                        sx={{ borderRadius: "5px" }}
                                        alt="User Image"
                                        src={
                                          s3baseUrl +
                                          data?.exercise?.image?.thumbnail_3
                                        }
                                      />
                                    )}
                                    <div className="label-text ms-1">
                                      {data?.exercise?.title
                                        ? data?.exercise?.title
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div className="exercise-action">
                                  <div style={{ fontSize: "16px" }}>
                                    {data?.exercise ? (
                                      <div>
                                        <InfoIcon
                                          onClick={() =>
                                            handleClickOpen(data?.exercise?._id)
                                          }
                                          className="d-block"
                                          fontSize="14px"
                                          sx={{
                                            opacity: 0.5,
                                            cursor: "pointer",
                                          }}
                                        />
                                        {!data.parameters.includes("Vdot") && (
                                          <EditIcon
                                            onClick={() =>
                                              handleExerciseList(mainindex)
                                            }
                                            fontSize="12px"
                                            sx={{
                                              opacity: 0.5,
                                              cursor: "pointer",
                                            }}
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <AddIcon
                                        onClick={() =>
                                          handleExerciseList(mainindex)
                                        }
                                        sx={{ opacity: 0.5, cursor: "pointer" }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Button
                              sx={{ textTransform: "inherit" }}
                              size="small"
                              color="primary"
                              variant="contained"
                              className="d-flex align-items-center ms-2 small-contained-dialog-button"
                              onClick={() => {
                                handleArrow(
                                  !data?.open ? true : false,
                                  mainindex
                                );
                              }}>
                              {!data?.open ? (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    cursor: "pointer",
                                    // opacity: 0.5,
                                    fontSize: "20px",
                                  }}
                                />
                              ) : (
                                <KeyboardArrowUpIcon
                                  sx={{
                                    cursor: "pointer",
                                    // opacity: 0.5,
                                    fontSize: "20px",
                                  }}
                                />
                              )}

                              <Typography
                                variant="body2"
                                sx={{
                                  // opacity: 0.6,
                                  whiteSpace: "nowrap",
                                }}>
                                {` Sets ${data?.set?.length}`}
                              </Typography>
                            </Button>
                          </div>

                          <div
                            className={`col-sm-12 ${"col-md-5"} col-lg-6 p-0 mt-2 mt-md-0 mt-lg-0 d-flex align-items-center justify-content-start `}>
                            {data?.exercise && (
                              <Button
                                sx={{ textTransform: "inherit" }}
                                size="small"
                                color="primary"
                                variant="contained"
                                className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                onClick={() => {
                                  handleHistoryOpen(data, mainindex);
                                }}>
                                <Icon icon="fa-solid:history" fontSize="10px" />
                                <Typography variant="body2" sx={{ ml: 0.5 }}>
                                  {`History`}
                                </Typography>
                              </Button>
                            )}
                          </div>
                        </div>

                        {data?.open &&
                          data?.set?.map((item, index1) => (
                            <div
                              className="d-flex align-items-center my-3 workout-card-inset"
                              onMouseEnter={() =>
                                handleMouseEnter(index1, mainindex)
                              }

                              // onMouseLeave={() => handleMouseLeave()}
                            >
                              <div>
                                {isHovered === index1 &&
                                  !data.parameters.includes("Vdot") &&
                                  isHoveredExercise == mainindex && (
                                    <div
                                      className="icon-calendar-workout"
                                      onMouseEnter={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }>
                                      <Tooltip title="Add Set">
                                        <AddIcon
                                          className="add-icon-calendar-workout me-1"
                                          onMouseEnter={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() =>
                                            handleAddSet(mainindex, index1)
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title={`${
                                          data?.set.length > 1
                                            ? "Delete Set"
                                            : "Can't be Deleted"
                                        }`}>
                                        <DeleteIcon
                                          onMouseEnter={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          className={`${
                                            data?.set.length > 1
                                              ? "del-icon-calendar-workout-working"
                                              : "del-icon-calendar-workout-notwork"
                                          }`}
                                          onClick={
                                            data?.set.length > 1
                                              ? () =>
                                                  handleDeleteSet(
                                                    mainindex,
                                                    index1
                                                  )
                                              : () => {}
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                  )}
                              </div>
                              {data.parameters.includes("Vdot") &&
                              item?.vdot &&
                              !item?.pace &&
                              !item?.paceUP ? (
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="start"
                                  sx={{
                                    padding: data.parameters.includes("Vdot")
                                      ? "9px 0px"
                                      : "",
                                    overflowX: "auto", // Add this line to enable horizontal scrolling
                                    "&::-webkit-scrollbar": {
                                      height: "5px", // Customize the scrollbar height
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      backgroundColor: "#888", // Customize the scrollbar thumb color
                                      borderRadius: "5px", // Optional: Customize the thumb border radius
                                    },
                                    "&::-webkit-scrollbar-track": {
                                      backgroundColor: "#f1f1f1", // Optional: Customize the track color
                                    },
                                  }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      opacity: 0.6,
                                      fontWeight: "600",
                                      fontSize: "12px",
                                    }}>
                                    Oops! this exercise not suitable for you.
                                    Please contact your coach.
                                  </Typography>
                                </Stack>
                              ) : data.parameters.includes("Vdot") &&
                                !item?.vdot ? (
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="start"
                                  sx={{
                                    padding: data.parameters.includes("Vdot")
                                      ? "9px 0px"
                                      : "",
                                    overflowX: "auto", // Add this line to enable horizontal scrolling
                                    "&::-webkit-scrollbar": {
                                      height: "5px", // Customize the scrollbar height
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      backgroundColor: "#888", // Customize the scrollbar thumb color
                                      borderRadius: "5px", // Optional: Customize the thumb border radius
                                    },
                                    "&::-webkit-scrollbar-track": {
                                      backgroundColor: "#f1f1f1", // Optional: Customize the track color
                                    },
                                  }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      opacity: 0.6,
                                      fontWeight: "600",
                                      fontSize: "12px",
                                    }}>
                                    Vdot is not calculated. Please{" "}
                                    <Link
                                      component={RouterLink}
                                      variant="subtitle2"
                                      to={`/edit-profile`}>
                                      Click here
                                    </Link>{" "}
                                    to calculate it.
                                  </Typography>
                                </Stack>
                              ) : (
                                <div className="d-flex align-items-center w-100">
                                  {!data.parameters.includes("Vdot") && (
                                    <div className="d-flex align-items-center">
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          padding: "25px 4px",
                                          color: "inherit",
                                          backgroundColor: "#edeff1",
                                          height: "100%",
                                          width: "15px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "7px",
                                        }}>
                                        {index1 + 1}
                                      </Typography>

                                      <CloseIcon sx={{ opacity: 0.3 }} />
                                    </div>
                                  )}
                                  <div className="row w-100">
                                    {data.parameters.includes("Vdot") && (
                                      <>
                                        <div
                                          className={`col-12 col-md-5 ${
                                            item?.race_type == "el"
                                              ? "col-lg-3"
                                              : "col-lg-3"
                                          } pe-0 py-2`}>
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Type"
                                            InputLabelProps={{
                                              className:
                                                "textfiels-input-label",
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              className:
                                                "textfiels-input-value",
                                              style: {
                                                fontSize: "13px",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",

                                                justifyContent: "center",
                                              },
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}>
                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="race_type"
                                                    value={item?.race_type}
                                                    label="Weight Type"
                                                    readOnly={true}
                                                    inputProps={{
                                                      style: {
                                                        padding: 0,
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    onChange={(e) => {
                                                      handleChangeVdotDistance(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      );
                                                    }}
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": {
                                                        top: 0,
                                                      },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      "& .MuiOutlinedInput-input":
                                                        {
                                                          padding: 0,
                                                        },
                                                    }}>
                                                    <MenuItem value="el">
                                                      Easy/Long
                                                    </MenuItem>
                                                    <MenuItem value="c">
                                                      Compromised
                                                    </MenuItem>
                                                    <MenuItem value="t">
                                                      Threshold
                                                    </MenuItem>
                                                    <MenuItem value="i">
                                                      Interval
                                                    </MenuItem>
                                                    <MenuItem value="r">
                                                      Repitition
                                                    </MenuItem>
                                                  </Select>

                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="pace_type"
                                                    value={item?.pace_type}
                                                    label="Weight Type"
                                                    readOnly={true}
                                                    inputProps={{
                                                      style: {
                                                        padding: 0,
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    onChange={(e) => {
                                                      handleChangeVdotPaceType(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      );
                                                    }}
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": {
                                                        top: 0,
                                                      },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      "& .MuiOutlinedInput-input":
                                                        {
                                                          padding: 0,
                                                        },
                                                    }}>
                                                    {item?.optionsType?.map(
                                                      (option) => {
                                                        return (
                                                          <MenuItem
                                                            key={option?.key}
                                                            value={option?.key}>
                                                            {option?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                              "& .css-1nuss9t": {
                                                width: "0px !important",
                                                padding: "9px 0px !important",
                                              },
                                              // "& input": {
                                              //   textAlign: "center",
                                              //   padding: "0px",
                                              // },
                                              width: "100%",
                                            }}
                                          />
                                        </div>
                                        {item?.race_type == "el" ? (
                                          <div
                                            className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}>
                                            <TextField
                                              id="outlined-basic"
                                              variant="outlined"
                                              label={getPaceText(
                                                item?.pace_type
                                              )}
                                              InputLabelProps={{
                                                className:
                                                  "textfiels-input-label",
                                                shrink: true,
                                                style: { fontSize: "14px" },
                                              }}
                                              InputProps={{
                                                className:
                                                  "textfiels-input-value",
                                                style: {
                                                  fontSize: "13px",
                                                  paddingLeft: "0px",
                                                  paddingRight: "0px",

                                                  justifyContent: "center",
                                                },

                                                readOnly: true,
                                                endAdornment: (
                                                  <InputAdornment
                                                    position="end"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}>
                                                    <TextField
                                                      id="outlined-basic-minutes"
                                                      type="number"
                                                      size="small"
                                                      placeholder="Min"
                                                      name="pace"
                                                      value={formatWithLeadingZeros(
                                                        Math.floor(
                                                          item?.pace / 60
                                                        )
                                                      )}
                                                      readOnly={true}
                                                      onChange={(e) =>
                                                        handleMinutesChange(
                                                          e,
                                                          mainindex,
                                                          index1
                                                        )
                                                      }
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "14px",
                                                          // width: "40px",
                                                        },
                                                        readOnly: true,
                                                      }}
                                                      sx={{
                                                        "& fieldset": {
                                                          border: "none",
                                                        },
                                                        // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                        //   {
                                                        //     padding: "0px !important",
                                                        //   },
                                                        "& input": {
                                                          textAlign: "center",
                                                          padding: "0px",
                                                        },
                                                        width: "100%",
                                                      }}
                                                    />
                                                    <div className="fs-6 fw-semibold ms-1 ">
                                                      :
                                                    </div>

                                                    <TextField
                                                      id="outlined-basic-seconds"
                                                      type="number"
                                                      size="small"
                                                      placeholder="Sec"
                                                      name="pace"
                                                      value={formatWithLeadingZeros(
                                                        item?.pace % 60
                                                      )}
                                                      onChange={(e) =>
                                                        handleSecondsChange(
                                                          e,
                                                          mainindex,
                                                          index1
                                                        )
                                                      }
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "14px",
                                                          // width: "40px",
                                                        },
                                                        readOnly: true,
                                                      }}
                                                      sx={{
                                                        "& fieldset": {
                                                          border: "none",
                                                        },
                                                        // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                        //   {
                                                        //     padding: "0px !important",
                                                        //   },
                                                        "& input": {
                                                          textAlign: "center",
                                                          padding: "0px",
                                                        },
                                                        width: "100%",
                                                      }}
                                                    />
                                                    <div className="fs-6 fw-semibold ms-1 me-1 ">
                                                      -
                                                    </div>
                                                    <TextField
                                                      id="outlined-basic-minutes"
                                                      type="number"
                                                      size="small"
                                                      placeholder="Min"
                                                      name="paceUp"
                                                      value={formatWithLeadingZeros(
                                                        Math.floor(
                                                          item?.paceUp / 60
                                                        )
                                                      )}
                                                      onChange={(e) =>
                                                        handleMinutesChange(
                                                          e,
                                                          mainindex,
                                                          index1
                                                        )
                                                      }
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "14px",
                                                          // width: "40px",
                                                        },
                                                        readOnly: true,
                                                      }}
                                                      sx={{
                                                        "& fieldset": {
                                                          border: "none",
                                                        },
                                                        // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                        //   {
                                                        //     padding: "0px !important",
                                                        //   },
                                                        "& input": {
                                                          textAlign: "center",
                                                          padding: "0px",
                                                        },
                                                        width: "100%",
                                                      }}
                                                    />
                                                    <div className="fs-6 fw-semibold ms-1 ">
                                                      :
                                                    </div>

                                                    <TextField
                                                      id="outlined-basic-seconds"
                                                      type="number"
                                                      size="small"
                                                      placeholder="Sec"
                                                      name="paceUp"
                                                      value={formatWithLeadingZeros(
                                                        item?.paceUp % 60
                                                      )}
                                                      onChange={(e) =>
                                                        handleSecondsChange(
                                                          e,
                                                          mainindex,
                                                          index1
                                                        )
                                                      }
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "14px",
                                                          // width: "40px",
                                                        },
                                                        readOnly: true,
                                                      }}
                                                      sx={{
                                                        "& fieldset": {
                                                          border: "none",
                                                        },
                                                        // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                        //   {
                                                        //     padding: "0px !important",
                                                        //   },
                                                        "& input": {
                                                          textAlign: "center",
                                                          padding: "0px",
                                                        },
                                                        width: "100%",
                                                        marginRight: 1,
                                                      }}
                                                    />
                                                  </InputAdornment>
                                                ),
                                              }}
                                              sx={{
                                                borderRadius: "5px",
                                                fontSize: "13px",
                                                "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                  {
                                                    width: "0px !important",
                                                    padding:
                                                      "9px 0px !important",
                                                  },
                                                "& .css-1nuss9t": {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                                // "& input": {
                                                //   textAlign: "center",
                                                //   padding: "0px",
                                                // },
                                                width: "100%",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            className={`col-12 col-md-2 col-lg-1 pe-0 py-2`}>
                                            <TextField
                                              id="outlined-basic"
                                              variant="outlined"
                                              label={getPaceText(
                                                item?.pace_type
                                              )}
                                              InputLabelProps={{
                                                className:
                                                  "textfiels-input-label",
                                                shrink: true,
                                                style: { fontSize: "14px" },
                                              }}
                                              InputProps={{
                                                className:
                                                  "textfiels-input-value",
                                                style: {
                                                  fontSize: "13px",
                                                  paddingLeft: "0px",
                                                  paddingRight: "0px",

                                                  justifyContent: "center",
                                                },
                                                readOnly: true,
                                                endAdornment: (
                                                  <InputAdornment
                                                    position="end"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}>
                                                    <TextField
                                                      id="outlined-basic-minutes"
                                                      type="number"
                                                      size="small"
                                                      placeholder="Min"
                                                      name="pace"
                                                      value={formatWithLeadingZeros(
                                                        Math.floor(
                                                          item?.pace / 60
                                                        )
                                                      )}
                                                      onChange={(e) =>
                                                        handleMinutesChange(
                                                          e,
                                                          mainindex,
                                                          index1
                                                        )
                                                      }
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "14px",
                                                          // width: "40px",
                                                        },
                                                        readOnly: true,
                                                      }}
                                                      sx={{
                                                        "& fieldset": {
                                                          border: "none",
                                                        },
                                                        // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                        //   {
                                                        //     padding: "0px !important",
                                                        //   },
                                                        "& input": {
                                                          textAlign: "center",
                                                          padding: "0px",
                                                        },
                                                        width: "100%",
                                                      }}
                                                    />
                                                    <div className="fs-6 fw-semibold ms-1 ">
                                                      :
                                                    </div>

                                                    <TextField
                                                      id="outlined-basic-seconds"
                                                      type="number"
                                                      size="small"
                                                      placeholder="Sec"
                                                      name="pace"
                                                      value={formatWithLeadingZeros(
                                                        item?.pace % 60
                                                      )}
                                                      onChange={(e) =>
                                                        handleSecondsChange(
                                                          e,
                                                          mainindex,
                                                          index1
                                                        )
                                                      }
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "14px",
                                                          // width: "40px",
                                                        },
                                                        readOnly: true,
                                                      }}
                                                      sx={{
                                                        "& fieldset": {
                                                          border: "none",
                                                        },
                                                        // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                        //   {
                                                        //     padding: "0px !important",
                                                        //   },
                                                        "& input": {
                                                          textAlign: "center",
                                                          padding: "0px",
                                                        },
                                                        width: "100%",
                                                      }}
                                                    />
                                                  </InputAdornment>
                                                ),
                                              }}
                                              sx={{
                                                borderRadius: "5px",
                                                fontSize: "13px",
                                                "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                  {
                                                    width: "0px !important",
                                                    padding:
                                                      "9px 0px !important",
                                                  },
                                                "& .css-1nuss9t": {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                                // "& input": {
                                                //   textAlign: "center",
                                                //   padding: "0px",
                                                // },
                                                width: "100%",
                                              }}
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                    {data.parameters.includes("Pace") && (
                                      <>
                                        <div
                                          className={`col-12 col-md-3 ${
                                            item?.race_type == "t"
                                              ? "col-lg-3"
                                              : "col-lg-2"
                                          } pe-0 py-2`}>
                                          <FormControl
                                            fullWidth
                                            className="py-1"
                                            readOnly={true}>
                                            <InputLabel
                                              className="set-Type-lable"
                                              sx={{ fontSize: "14px" }}
                                              id="demo-simple-select-label">
                                              Type
                                            </InputLabel>
                                            <Select
                                              className="set-Type-value"
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="race_type"
                                              value={item?.race_type}
                                              label="Type"
                                              readOnly
                                              inputProps={{
                                                style: {
                                                  padding: 0,
                                                },
                                                readOnly: true,
                                              }}
                                              onChange={(e) => {
                                                handleChangeVdotDistance(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              sx={{
                                                width: "100%",
                                                // "& legend": {
                                                //   display: "none",
                                                // },
                                                // "& fieldset": {
                                                //   top: 0,
                                                // },
                                                fontSize: "13px",

                                                "& .MuiOutlinedInput-input": {
                                                  paddingLeft: 1,
                                                  paddingRight:
                                                    "16px !important",
                                                },
                                              }}>
                                              <MenuItem value="el">
                                                Easy/Long
                                              </MenuItem>
                                              <MenuItem value="c">
                                                Compromised
                                              </MenuItem>
                                              <MenuItem value="t">
                                                Threshold/Tempo
                                              </MenuItem>
                                              <MenuItem value="i">
                                                Interval
                                              </MenuItem>
                                              <MenuItem value="r">
                                                Repitition
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                        <div
                                          className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}>
                                          <TextField
                                            sx={{
                                              borderRadius: "5px",
                                              paddingRight: 0,
                                              width: "100%",
                                            }}
                                            size="small"
                                            id="custom-input"
                                            variant="outlined"
                                            onMouseLeave={() =>
                                              handleMouseEnter(
                                                index1,
                                                mainindex
                                              )
                                            }
                                            onClick={() => handleMouseLeave()}
                                            placeholder={"Distance"}
                                            name="distance"
                                            label="Distance"
                                            value={item?.distance}
                                            onChange={(e) => {
                                              handleChange(
                                                e,
                                                mainindex,
                                                index1
                                              );
                                            }}
                                            readOnly={true}
                                            type="number"
                                            InputLabelProps={{
                                              className:
                                                "textfiels-input-label",
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              className:
                                                "textfiels-input-value",
                                              style: {
                                                fontSize: "13px",
                                                paddingRight: 0,
                                              },
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="distanceType"
                                                    value={item?.distanceType}
                                                    label="Weight Type"
                                                    onChange={(e) => {
                                                      handleChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      );
                                                    }}
                                                    onMouseLeave={() =>
                                                      handleMouseEnter(
                                                        index1,
                                                        mainindex
                                                      )
                                                    }
                                                    readOnly={true}
                                                    onClick={() =>
                                                      handleMouseLeave()
                                                    }
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": { top: 0 },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                    }}>
                                                    <MenuItem value="m">
                                                      Meter
                                                    </MenuItem>
                                                    <MenuItem value="km">
                                                      KM
                                                    </MenuItem>
                                                    <MenuItem value="mi">
                                                      Mile
                                                    </MenuItem>
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </div>
                                        <div
                                          className={`col-12 col-md-2 col-lg-2 pe-0 py-2`}>
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            label={getPaceTextinPaces(
                                              item?.distanceType
                                            )}
                                            InputLabelProps={{
                                              className:
                                                "textfiels-input-label",
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              className:
                                                "textfiels-input-value",
                                              style: {
                                                fontSize: "13px",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",

                                                justifyContent: "center",
                                              },
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}>
                                                  <TextField
                                                    id="outlined-basic-minutes"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Min"
                                                    name="pace"
                                                    value={formatWithLeadingZeros(
                                                      Math.floor(
                                                        item?.pace / 60
                                                      )
                                                    )}
                                                    onChange={(e) =>
                                                      handleMinutesChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                  <div className="fs-6 fw-semibold ms-1 ">
                                                    :
                                                  </div>

                                                  <TextField
                                                    id="outlined-basic-seconds"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Sec"
                                                    name="pace"
                                                    value={formatWithLeadingZeros(
                                                      item?.pace % 60
                                                    )}
                                                    onChange={(e) =>
                                                      handleSecondsChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                              "& .css-1nuss9t": {
                                                width: "0px !important",
                                                padding: "9px 0px !important",
                                              },
                                              // "& input": {
                                              //   textAlign: "center",
                                              //   padding: "0px",
                                              // },
                                              width: "100%",
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "4px",
                                            left: "5px",
                                          }}>
                                          <Typography
                                            variant="subtitle2"
                                            sx={{
                                              opacity: 0.6,
                                              fontWeight: "600",
                                              fontSize: "12px",
                                            }}>{` @ Speed ${getKmhrForPace(
                                            item?.distance,
                                            item?.pace,
                                            item?.distanceType
                                          )} km/hr or ${getMihrForPace(
                                            item?.distance,
                                            item?.pace,
                                            item?.distanceType
                                          )} mi/hr`}</Typography>
                                        </div>
                                      </>
                                    )}
                                    {data.parameters.includes("Distance") && (
                                      <div
                                        className={`col-12 col-md-3 col-lg-2 pe-0 py-1`}>
                                        <TextField
                                          sx={{
                                            borderRadius: "5px",
                                            // "& legend": { display: "none" },
                                            // "& fieldset": { top: 0 },
                                            paddingRight: 0,
                                            // width: "45%",
                                            width: "100%",
                                            // flexGrow: 1,
                                            marginTop: "5px",
                                          }}
                                          // className={classes.customInput}
                                          size="small"
                                          id="custom-input"
                                          variant="outlined"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          placeholder={"Distance"}
                                          name="distance"
                                          label="Distance"
                                          value={item?.distance}
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          type="number"
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },

                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="distanceType"
                                                  value={item?.distanceType}
                                                  label="Weight Type"
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  onMouseLeave={() =>
                                                    handleMouseEnter(
                                                      index1,
                                                      mainindex
                                                    )
                                                  }
                                                  onClick={() =>
                                                    handleMouseLeave()
                                                  }
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                  }}>
                                                  <MenuItem value="m">
                                                    Meter
                                                  </MenuItem>
                                                  <MenuItem value="km">
                                                    KM
                                                  </MenuItem>
                                                  <MenuItem value="mi">
                                                    Mile
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </div>
                                    )}
                                    {(data.parameters.includes(
                                      "Duration/Pace"
                                    ) ||
                                      data.parameters.includes("Time")) && (
                                      <div
                                        className={`col-12 col-md-3 col-lg-2 pe-0 py-1`}>
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          placeholder="0"
                                          type="number"
                                          value={item?.time}
                                          name="time"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          label={
                                            data.parameters.includes(
                                              "Duration/Pace"
                                            )
                                              ? "Duration/Pace"
                                              : "Time"
                                          }
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },

                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="timeType"
                                                  value={item?.timeType}
                                                  label="Weight Type"
                                                  inputProps={{
                                                    style: {
                                                      padding: 0,
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}>
                                                  <MenuItem value="sec">
                                                    sec
                                                  </MenuItem>
                                                  <MenuItem value="min">
                                                    min
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            fontSize: "13px",
                                            width: "100%",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </div>
                                    )}

                                    {data.parameters.includes("Reps") && (
                                      <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="Reps"
                                          type="number"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          label="Reps"
                                          value={item?.reps}
                                          name="reps"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: { fontSize: "13px" },
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            // "& legend": { display: "none" },
                                            // "& fieldset": { top: 0 },
                                            fontSize: "13px",
                                            // width: "13%",
                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Height") && (
                                      <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="0"
                                          type="number"
                                          value={item?.height}
                                          name="height"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: {
                                              fontSize: "14px",
                                            },
                                          }}
                                          label="Height"
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="heightType"
                                                  value={item?.heightType}
                                                  label="Height Type"
                                                  inputProps={{
                                                    style: {
                                                      padding: 0,
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}>
                                                  <MenuItem value="cm">
                                                    Cm
                                                  </MenuItem>
                                                  <MenuItem value="ft">
                                                    Feet
                                                  </MenuItem>
                                                  <MenuItem value="inch">
                                                    Inch
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            fontSize: "13px",
                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Weight") && (
                                      <div className="col-12 col-md-4 col-lg-2 pe-0 py-2">
                                        <TextField
                                          sx={{
                                            borderRadius: "5px",
                                            // "& legend": { display: "none" },
                                            // "& fieldset": { top: 0 },
                                            paddingRight: 0,
                                            // width: "45%",
                                            width: "100%",
                                          }}
                                          // className={classes.customInput}
                                          size="small"
                                          id="custom-input"
                                          variant="outlined"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          placeholder="Weight"
                                          name="weight"
                                          label="Weight"
                                          value={item.weight}
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          type="number"
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="weightType"
                                                  value={item?.weightType}
                                                  label="Weight Type"
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  onMouseLeave={() =>
                                                    handleMouseEnter(
                                                      index1,
                                                      mainindex
                                                    )
                                                  }
                                                  onClick={() =>
                                                    handleMouseLeave()
                                                  }
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": { top: 0 },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                  }}>
                                                  <MenuItem value="1rm">
                                                    % 1RM
                                                  </MenuItem>
                                                  <MenuItem value="body_weight">
                                                    % BW
                                                  </MenuItem>
                                                  <MenuItem value="kg">
                                                    KG
                                                  </MenuItem>
                                                  <MenuItem value="lb">
                                                    Pound
                                                  </MenuItem>
                                                  <MenuItem value="poods">
                                                    Poods
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes(
                                      "Distance/Calories"
                                    ) && (
                                      <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                        <TextField
                                          sx={{
                                            borderRadius: "5px",
                                            paddingRight: 0,
                                            width: "100%",
                                          }}
                                          size="small"
                                          id="custom-input"
                                          variant="outlined"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          placeholder={
                                            item?.distanceType == "calories"
                                              ? "Calories"
                                              : "Distance"
                                          }
                                          name="distance"
                                          label="Distance/Calories"
                                          value={item?.distance}
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          type="number"
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="distanceType"
                                                  value={item?.distanceType}
                                                  label="Weight Type"
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  onMouseLeave={() =>
                                                    handleMouseEnter(
                                                      index1,
                                                      mainindex
                                                    )
                                                  }
                                                  onClick={() =>
                                                    handleMouseLeave()
                                                  }
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": { top: 0 },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}>
                                                  <MenuItem value="m">
                                                    Meter
                                                  </MenuItem>
                                                  <MenuItem value="km">
                                                    KM
                                                  </MenuItem>
                                                  <MenuItem value="mi">
                                                    Mile
                                                  </MenuItem>
                                                  <MenuItem value="calories">
                                                    Calories
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("%MHR") && (
                                      <div className="col-12 col-md-2 col-lg-1 pe-0 py-1">
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="%MHR"
                                          type="number"
                                          label="%MHR"
                                          value={item?.mhr}
                                          name="mhr"
                                          onChange={(e) => {
                                            handleChangeTempo(
                                              e,
                                              mainindex,
                                              index1
                                            );
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                          sx={{
                                            borderRadius: "5px",

                                            fontSize: "13px",

                                            width: "100%",

                                            marginTop: "5px",

                                            "& .MuiOutlinedInput-input": {
                                              padding: "8px 7px",
                                            },
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Tempo") && (
                                      <div className="col-12 col-md-2 col-lg-1 pe-0 py-1">
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="Tempo"
                                          type="number"
                                          label="Tempo"
                                          value={item?.tempo}
                                          name="tempo"
                                          onChange={(e) => {
                                            handleChangeTempo(
                                              e,
                                              mainindex,
                                              index1
                                            );
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                            },
                                            readOnly: true,
                                          }}
                                          sx={{
                                            borderRadius: "5px",

                                            fontSize: "13px",

                                            width: "100%",

                                            marginTop: "5px",

                                            "& .MuiOutlinedInput-input": {
                                              padding: "8px 7px",
                                            },
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Rest") && (
                                      <div className="col-12 col-md-3 col-lg-2 pe-0 py-1">
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="0"
                                          type="number"
                                          value={item?.rest}
                                          name="rest"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          label="Rest"
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="restType"
                                                  value={item?.restType}
                                                  label="Weight Type"
                                                  inputProps={{
                                                    style: {
                                                      padding: 0,
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}>
                                                  <MenuItem value="sec">
                                                    sec
                                                  </MenuItem>
                                                  <MenuItem value="min">
                                                    min
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                          sx={{
                                            borderRadius: "5px",

                                            marginTop: "5px",
                                            fontSize: "13px",

                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Vdot") && (
                                      <div>
                                        {item?.race_type == "el" ? (
                                          <Typography
                                            variant="subtitle2"
                                            sx={{
                                              opacity: 0.6,
                                              fontWeight: "600",
                                              fontSize: "12px",
                                            }}>{` @ Speed ${getKmhr(
                                            item?.pace,
                                            item?.pace_type
                                          )} km/hr or ${getMihr(
                                            item?.pace,
                                            item?.pace_type
                                          )} mi/hr -  ${getKmhr(
                                            item?.paceUp,
                                            item?.pace_type
                                          )} km/hr or ${getMihr(
                                            item?.paceUp,
                                            item?.pace_type
                                          )} mi/hr`}</Typography>
                                        ) : (
                                          <Typography
                                            variant="subtitle2"
                                            sx={{
                                              opacity: 0.6,
                                              fontWeight: "600",
                                              fontSize: "12px",
                                            }}>{` @ Speed ${getKmhr(
                                            item?.pace,
                                            item?.pace_type
                                          )} km/hr or ${getMihr(
                                            item?.pace,
                                            item?.pace_type
                                          )} mi/hr`}</Typography>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        {data?.open && (
                          <>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              variant="outlined"
                              placeholder="Instructions"
                              label="Instructions"
                              type="number"
                              value={data.description}
                              name="description"
                              multiline
                              rows={data.description ? null : 2}
                              // onChange={(e) => handleChangeDes(e, mainindex)}
                              InputLabelProps={{
                                className: "textfiels-input-label",
                                shrink: true,
                                style: { fontSize: "14px" },
                              }}
                              InputProps={{
                                className: "textfiels-input-value",
                                style: { fontSize: "14px" },
                                readOnly: true,
                              }}
                              sx={{
                                borderRadius: "5px",
                                // "& legend": { display: "none" },
                                // "& fieldset": { top: 0 },

                                mt: 2,
                                width: "100%",
                              }}
                            />

                            <div className="d-flex justify-content-end mt-3">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        data.parameters.includes("Vdot") &&
                                        (!data?.set[0].vdot ||
                                          (data?.set[0].pace == null &&
                                            data?.set[0].paceUp == null))
                                          ? true
                                          : false
                                      }
                                      sx={{ p: "3px" }}
                                      checked={data.is_completed}
                                      onChange={(e) =>
                                        handleChangeComplete(e, mainindex)
                                      }
                                    />
                                  }
                                  label="Mark as Complete" // workout mark as complete
                                />
                              </FormGroup>
                            </div>
                          </>
                        )}
                        {data?.open && (
                          <div className="row mt-3 d-flex justify-content-center">
                            <div className="col-md-2 col-lg-1 col-3">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();

                                  handleArrow(false, mainindex);
                                }}
                                className="w-100 small-contained-set-button">
                                <KeyboardArrowUpIcon
                                  sx={{
                                    cursor: "pointer",
                                    opacity: 0.5,
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    handleArrow(false, mainindex);
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                        )}
                        {/* <span
                          className="cross-icon"
                          style={{
                            position: "absolute",
                            bottom: "-37px",

                            width: "100%",
                            justifyContent: "center",
                            zIndex: 1,
                        
                          }}
                        >
                          {addArray.length > 1 &&
                          mainindex < addArray.length - 1 ? (
                            <div className="">
                              <SyncIcon
                                onClick={() => handleAgreeExercise(mainindex)}
                                className="sync-icon-add"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </span> */}
                      </Box>
                    </div>
                  )}
                </>
              );
            })}
          </form>
        </div>
      </div>
    </div>
  );
};
export default WorkoutExercisesAnswerUI;
