import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText, Input, InputLabel } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ReactVideoPlayer } from "src/components";
function WorkoutCalendervideoModel({ url, onOpen, setOnOpen }) {
  console.log("onOpen", onOpen);
  // const [open, setOpen] = useState(onOpen);

  const handleClose = () => {
    // setOpen(false);
    setOnOpen(false);
  };

  return (
    <div>
      <Modal
        open={onOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Box
          sx={{
            borderRadius: "7px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "65%",

            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            border: "none",
            overflow: "auto",
            maxHeight: "90vh",
          }}>
          <CloseIcon
            onClick={handleClose}
            sx={{ position: "absolute", top: 3, right: 3 }}
          />
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <ReactVideoPlayer url={url} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default WorkoutCalendervideoModel;
