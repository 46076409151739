import React from "react";
import ReactPlayer from "react-player";

const ReactVideoPlayerPosts = (props) => (
  <>
    <div className="wrapper">
      <ReactPlayer
        controls
        className="player"
        url={props.url}
        width="100%"
        height={props.height ? props.height : "100%"}
        stopOnUnmount
        pip
        playsinline
      />
    </div>
  </>
);

export default ReactVideoPlayerPosts;
