import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Box, Card, Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
// utils
import Label from "../../Label";
import { s3baseUrl } from "../../../config/config";
import ConfirmationWithDate from "./ConfirmationWithDate";
import { useEffect, useState } from "react";

// ----------------------------------------------------------------------

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

ProgrammesCardFirstIndex.propTypes = {
  product: PropTypes.object,
};

export default function ProgrammesCardFirstIndex({ programm, reload }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(null);

  const addAgreeInSuperSet = () => {
    setOpen(false);
    console.log("kjsadhfkjashdkjfhakj", open);
  };
  const {
    _id,
    title,
    program_slug,
    short_description,
    program_images,
    locked_program_info,
    no_of_lesson,
    total_lesson_duration,
    program_lock_status,
  } = programm;
  const handleNavigateCalendar = (value) => {
    console.log("edit value", value);
    if (!value?.start_date && value?.program_length_type !== "limited") {
      navigate(`/programmes-calendar/${value?._id?.program_slug}`, {
        state: value,
      });
    } else {
      setOpen(true);
    }
  };
  useEffect(() => {
    console.log("kjsadhfkjashdkjfhakj", open);
  }, [open]);
  return (
    <div>
      <ConfirmationWithDate
        open={open}
        setOpen={setOpen}
        programId={programm?._id?._id}
        title={"Are you sure you want to take this action?"}
        handleAgree={addAgreeInSuperSet}
        reload={reload}
      />
      <Card
        className="programm-card h-100 secondary-background"
        sx={{ cursor: "pointer" }}
      >
        <Box
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleNavigateCalendar(programm)}
        >
          {program_lock_status === true ? (
            <button
              variant="contained"
              className="small-contained-button program-lock-icon"
              style={{
                zIndex: 9,
                top: "45%",
                left: "40%",
                fontSize: "14px",
                position: "absolute",
                padding: "10px 20px",
              }}
            >
              <LockIcon className="lock-icon-color" />
              &nbsp;&nbsp;Unlock Access
            </button>
          ) : (
            ""
          )}
          <ProgrammesImgStyle
            alt={title}
            src={s3baseUrl + programm?._id?.program_images?.thumbnail_1}
          />
        </Box>

        <Stack spacing={2} className="programme-content ">
          <h3>{title}</h3>
          <p className="programme-card-desc">{short_description}</p>
          <div className="card-button">
            <p className="programme-duration">
              <span>{no_of_lesson} workout</span>
            </p>
          </div>
        </Stack>
      </Card>
    </div>
  );
}
