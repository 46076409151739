import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// api
import {
  confirmEmail,
  confirmPinCode,
  updatePassword,
} from "../../DAL/Login/Login";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import AuthSocial from "../../components/authentication/AuthSocial";
import EmailForm from "./components/EmailForm";
import PinCodeForm from "./components/PinCodeForm";
import NewPasswordForm from "./components/NewPasswordForm";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { s3baseUrl } from "src/config/config";
import { get_page_content_localStorage } from "src/DAL/localStorage/localStorage";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgetPassword() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(0);
  const [adminEmail, setAdminEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { projectInfo } = useContentSetting();
  const content_data = get_page_content_localStorage();

  const handleSubmitEmail = async (_email) => {
    setAdminEmail(_email);
    const formData = new FormData();
    formData.append("email", _email);
    const result = await confirmEmail(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setStatus(1);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmitPinCode = async (pinCode) => {
    const formData = new FormData();
    formData.append("email", adminEmail);
    formData.append("verification_code", pinCode);
    const result = await confirmPinCode(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setStatus(2);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmitNewPassword = async (newPassword, confirmPassword) => {
    const formData = new FormData();
    formData.append("email", adminEmail);
    formData.append("password", newPassword);
    formData.append("confirm_password", confirmPassword);
    const result = await updatePassword(formData);
    if (result.code === 200) {
      setStatus(0);
      navigate("/login");
      enqueueSnackbar("Password Changed Successfully", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  return (
    <div
    // className="whole-background"
    // style={
    //   status === 0
    //     ? {
    //         backgroundImage: `url(${
    //           s3baseUrl + content_data?.forgot_password_page_image
    //         })`,
    //       }
    //     : {
    //         backgroundImage: `url(${
    //           s3baseUrl + content_data?.email_verification_page_image
    //         })`,
    //       }
    // }
    >
      {" "}
      <RootStyle>
        <HeaderStyle>
          <RouterLink to="/">{/* <Logo /> */}</RouterLink>

          <MHidden width="smDown">
            <Typography
              variant="body2"
              sx={{
                mt: { md: -2 },
              }}
            >
              Back to Login? &nbsp;
              <Link
                underline="none"
                variant="subtitle2"
                component={RouterLink}
                to="/login"
              >
                Login
              </Link>
            </Typography>
          </MHidden>
        </HeaderStyle>

        <MHidden width="mdDown">
          <SectionStyle>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 10, mb: 5, color: "black" }}
            >
              Hi, Welcome Back
            </Typography>
            {status === 0 ? (
              <div className="d-flex justify-content-center ">
                <img
                  src={s3baseUrl + content_data?.forgot_password_page_image}
                  alt="login"
                  style={{ width: 275, height: 250 }}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center ">
                <img
                  src={s3baseUrl + content_data?.email_verification_page_image}
                  alt="login"
                  style={{ width: 275, height: 250 }}
                />
              </div>
            )}
          </SectionStyle>
        </MHidden>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 2 }}>
              {status === 0 ? (
                <>
                  <Typography variant="h4" gutterBottom>
                    Forgot Password
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Enter your Email.
                  </Typography>
                </>
              ) : (
                ""
              )}
              {status === 1 ? (
                <>
                  <Typography variant="h4" gutterBottom>
                    Please check your email
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Enter PIN Code here.
                  </Typography>
                </>
              ) : (
                ""
              )}
              {status === 2 ? (
                <>
                  <Typography variant="h4" gutterBottom>
                    Forget Password
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Enter your New Password.
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Stack>
            {status === 0 ? (
              <EmailForm
                onhandleEmailSubmit={handleSubmitEmail}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            ) : (
              ""
            )}
            {status === 1 ? (
              <PinCodeForm
                onhandlePinCodeSubmit={handleSubmitPinCode}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            ) : (
              ""
            )}
            {status === 2 ? (
              <NewPasswordForm
                onhandleNewPasswordSubmit={handleSubmitNewPassword}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            ) : (
              ""
            )}
            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Back to Login?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="/login">
                  Login
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>
      </RootStyle>
    </div>
  );
}
