import { invokeApi } from "../../bl_libs/invokeApi";

export const live_events_list_api = async () => {
  const requestObj = {
    path: `api/member/get_member_event/v1`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _list_of_member_for_chat_against_event = async (
  event_slug,
  search_text
) => {
  const requestObj = {
    path: `api/chat/list_of_member_for_chat_against_event/v1/${event_slug}?search_text=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const live_event_new_detail_api = async (id) => {
  const requestObj = {
    path: `api/member/get_member_event_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_member_event_detail_by_slug = async (event_id) => {
  const requestObj = {
    path: `api/member/get_member_event_detail_by_id/v1/${event_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const token_generate_for_upgrade_portal_api = async (data) => {
  const requestObj = {
    path: `api/member/token_generate_for_upgrade_portal`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_image_on_s3_for_chat = async (data) => {
  const requestObj = {
    path: `app/update_image_on_s3_for_chat/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_image_on_s3_for_chat = async (data) => {
  const requestObj = {
    path: `app/delete_image_on_s3_for_chat/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
